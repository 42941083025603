import { memo, useRef } from 'react';

import { Modal, Table } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useInView } from 'framer-motion';

import { Contact, ContactTableColumn, Organization } from '../types';

import { ContactForm } from './ContactForm';
import { TableCell } from './TableCell';

interface ContactTableRowProps {
  contact: Contact;
  organizationsById: Record<string, Organization>;
  filters: {
    organizationIds?: string[];
  };
  columnWidths: {
    [key in ContactTableColumn]: number;
  };
}

export const ContactTableRow = memo(({ contact, organizationsById, filters, columnWidths }: ContactTableRowProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '0px 0px',
    amount: 0,
  });

  return (
    <>
      <Table.Tr ref={ref} h={46}>
        {isInView
          ? Object.entries(columnWidths).map(([key, width], index) => (
              <TableCell
                key={key}
                columnKey={key as ContactTableColumn}
                width={width}
                isSticky={index === 0}
                contact={contact}
                filters={filters}
                organizationsById={organizationsById}
                onEdit={open}
              />
            ))
          : // Render empty cells with correct widths when not in view
            Object.entries(columnWidths).map(([key, width]) => <Table.Td key={key} style={{ width: width }} />)}
      </Table.Tr>

      {isInView && (
        <Modal opened={opened} onClose={close} title="Edit Contact" size="lg">
          <ContactForm
            initialData={{ ...contact, id: contact.id }}
            organizations={Object.values(organizationsById)}
            onSubmit={close}
            onCancel={close}
          />
        </Modal>
      )}
    </>
  );
});

ContactTableRow.displayName = 'ContactTableRow';
