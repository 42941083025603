import React from 'react';

import { Badge, Box, Group, Stack, Text } from '@mantine/core';
import { IconAlertCircle, IconCalendarClock, IconMail } from '@tabler/icons-react';

import { TooltipIcon } from '@components/TooltipIcon';

import { InSchedulingMeeting } from '../types';

import { AttendeeList } from './AttendeeList';

interface InSchedulingItemProps {
  meeting: InSchedulingMeeting;
}

export const InSchedulingItem: React.FC<InSchedulingItemProps> = ({ meeting }) => {
  const {
    emailSubject,
    codewordTemplateName,
    threadSummary,
    attendees,
    numberOfDaysUntilNudge,
    updatedAt,
    isWaitingForSidechainResponse,
  } = meeting;

  return (
    <Stack gap="xs">
      <Group justify="space-between" align="flex-start">
        <Box style={{ flex: 1 }}>
          <Stack gap="xs">
            {emailSubject && (
              <Group gap="xs" align="center" wrap="nowrap">
                <TooltipIcon title="Email Subject" icon={<IconMail size={20} />} size="sm" />
                <Text size="md" fw={500}>
                  {emailSubject}
                </Text>
                {codewordTemplateName && (
                  <Badge size="sm" variant="light" color="blue">
                    {codewordTemplateName}
                  </Badge>
                )}
              </Group>
            )}
            {threadSummary && (
              <Text size="sm" c="dimmed" lineClamp={2}>
                {threadSummary}
              </Text>
            )}
            <AttendeeList attendees={attendees} />
            {numberOfDaysUntilNudge !== null && (
              <Group gap="xs" align="center">
                <TooltipIcon title="Scheduled actions" icon={<IconCalendarClock size={16} />} />
                <Text size="sm">Nudge in {numberOfDaysUntilNudge} weekdays</Text>
              </Group>
            )}
          </Stack>
        </Box>
        {isWaitingForSidechainResponse && (
          <TooltipIcon
            c="red"
            title="Waiting for response to sidechain"
            icon={<IconAlertCircle size={20} color="red" />}
            size="sm"
          />
        )}
      </Group>
      <Text size="xs" c="dimmed">
        Last activity: {updatedAt.toRelative()}
      </Text>
    </Stack>
  );
};
