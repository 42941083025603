import { Box, Tabs } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

import { UPDATE_NAV_LINKS } from './constants';
import { NavLink } from './NavLink';

export const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <Box visibleFrom="xl">
        {UPDATE_NAV_LINKS.map((navLink) => (
          <NavLink key={navLink.label} {...navLink} isActive={location.pathname.endsWith(navLink.relativePath)} />
        ))}
      </Box>
      <Box hiddenFrom="xl">
        <Tabs
          value={UPDATE_NAV_LINKS.find((navLink) => location.pathname.endsWith(navLink.relativePath))?.relativePath}
          onChange={(value) => {
            if (value) {
              navigate(value);
            }
          }}
        >
          <Tabs.List>
            {UPDATE_NAV_LINKS.map((navLink) => (
              <Tabs.Tab key={navLink.label} value={navLink.relativePath}>
                {navLink.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      </Box>
    </>
  );
};
