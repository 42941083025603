import { FC } from 'react';

import { Group, Pill, Text } from '@mantine/core';

import { DayOfWeek } from '@/types';
import { formatTimeRange } from '@/utils/dateTime';
import { DAY_OF_WEEK_LABELS_SHORT } from '@constants/index';
import { usePreferences } from '@features/preferences';
import { Availability, PreferenceSettings } from '@features/preferences/types';

import { comparePreferences, formatKeyAndValue, KEYS_TO_DISPLAY } from '../utils/preferenceFormatting';

interface DifferentPreferencesProps {
  newPreferences: PreferenceSettings;
}

export const DifferentPreferences: FC<DifferentPreferencesProps> = ({ newPreferences }) => {
  const { data: userPreferences } = usePreferences();
  if (!userPreferences) return null;

  const differentPreferences = comparePreferences(newPreferences, userPreferences);
  const hasNoPreferredBlocks =
    differentPreferences.availability &&
    Object.values(differentPreferences.availability).every((blocks) => blocks.length === 0);
  const usesDefaultPreferredBlocks = differentPreferences.availability === null;

  const preferencesToShow = Object.entries(differentPreferences).filter(([key]) =>
    KEYS_TO_DISPLAY.includes(key as keyof PreferenceSettings),
  );

  const formatAvailability = (availability: Availability) => {
    return (
      <Group gap="xs">
        {Object.entries(availability).map(
          ([day, blocks]) =>
            blocks.length > 0 && (
              <Group key={day} gap="xs">
                <Text size="sm">{DAY_OF_WEEK_LABELS_SHORT[day as DayOfWeek]}:</Text>
                {blocks.map((block, index) => (
                  <Pill key={index} size="sm">
                    {formatTimeRange(block.start, block.end)}
                  </Pill>
                ))}
              </Group>
            ),
        )}
      </Group>
    );
  };

  return (
    <>
      <Group>
        <Text fw={600} span>
          Preferred blocks:
        </Text>
        {(usesDefaultPreferredBlocks ||
          hasNoPreferredBlocks ||
          (!usesDefaultPreferredBlocks && !hasNoPreferredBlocks && !differentPreferences.availability)) && (
          <Text c="dimmed" span>
            {usesDefaultPreferredBlocks
              ? 'Your default preferred blocks will be used'
              : hasNoPreferredBlocks
                ? 'You have no preferred blocks'
                : 'Same as default'}
          </Text>
        )}
        {!usesDefaultPreferredBlocks &&
          !hasNoPreferredBlocks &&
          differentPreferences.availability &&
          formatAvailability(differentPreferences.availability as Availability)}
      </Group>
      {preferencesToShow.length > 0 && (
        <Group gap="xs">
          <Text fw={600} span>
            Custom settings:
          </Text>
          {preferencesToShow.map(([key, value]) => (
            <Pill key={key} size="sm">
              {formatKeyAndValue(key, value, newPreferences)}
            </Pill>
          ))}
        </Group>
      )}
    </>
  );
};
