import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DateTime } from 'luxon';

const GCAL_API_URL = 'https://www.googleapis.com/calendar/v3/calendars/primary/events';

export interface GoogleCalendarEvent {
  id: string;
  summary: string;
  start: {
    date?: string;
    dateTime?: string;
    timeZone?: string;
  };
  end: {
    date?: string;
    dateTime?: string;
    timeZone?: string;
  };
  status: string;
  htmlLink: string;
  created: string;
  updated: string;
  creator: {
    email: string;
    self?: boolean;
  };
  organizer: {
    email: string;
    self?: boolean;
  };
  attendees?: Array<{
    email: string;
    responseStatus: string;
    self?: boolean;
  }>;
  description?: string;
}

interface GoogleCalendarAccessToken {
  accessToken: string;
}

const fetchCalendarEvents = async (request?: GoogleCalendarAccessToken): Promise<GoogleCalendarEvent[] | null> => {
  if (!request) {
    return null;
  }

  const today = DateTime.now();
  const startingMonday = today.minus({ weeks: 4 }).startOf('week'); // Go back to the Monday 4 weeks ago
  const endOfLastWeek = today.minus({ weeks: 1 }).endOf('week'); // Move to the last Sunday

  const queryParams = {
    timeMin: startingMonday.toISO(),
    timeMax: endOfLastWeek.toISO(),
    showDeleted: 'false',
    singleEvents: 'true',
    maxResults: '500',
    orderBy: 'startTime',
  };

  const url = `${GCAL_API_URL}?${new URLSearchParams(queryParams).toString()}`;

  const response = await axios.get(url, {
    headers: {
      authorization: `Bearer ${request.accessToken}`,
    },
  });

  if (response.status !== 200) {
    throw new Error('Failed to fetch calendar events');
  }

  const data = response.data;
  return data.items as GoogleCalendarEvent[];
};

export const useFetchCalendarEvents = (accessToken?: GoogleCalendarAccessToken, isPersonalCalendar?: boolean) => {
  return useQuery({
    queryKey: ['calendarEventsForWaitlistForm', accessToken],
    queryFn: () => fetchCalendarEvents(accessToken),
    enabled: !!accessToken && !isPersonalCalendar,
  });
};
