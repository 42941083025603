import { Route, Routes, useRoutes } from 'react-router-dom';

import { CodewordTemplatePage } from '../components/CodewordTemplatePage';

import { updateRoutes } from './UpdateRoutes';

export const CodewordTemplateRoutes = () => {
  const updateElement = useRoutes(updateRoutes);
  return (
    <>
      <Routes>
        <Route path="/" element={<CodewordTemplatePage />} />
      </Routes>
      {updateElement}
    </>
  );
};
