import { useMemo } from 'react';

import { ContactFilters } from '../components/ContactsFilters';
import { Contact, Organization, SortState } from '../types';

const useFilteredContacts = (
  contacts: Contact[],
  filters: ContactFilters,
  sort: SortState,
  organizationsById: Record<string, Organization>,
) => {
  return useMemo(() => {
    let filtered = contacts.filter((contact) => {
      const matchesSearch = !filters.search
        ? true
        : [
            contact.firstName,
            contact.lastName,
            contact.currentTitle,
            contact.emails.join(' '),
            organizationsById[contact.currentOrganizationId]?.name,
            contact.location,
          ]
            .join(' ')
            .toLowerCase()
            .includes(filters.search.toLowerCase());

      const matchesOrganizations = !filters.organizationIds?.length
        ? true
        : (contact.currentOrganizationId && filters.organizationIds.includes(contact.currentOrganizationId)) ||
          contact.pastOrganizationIds?.some((orgId) => filters.organizationIds?.includes(orgId));

      const matchesLocations = !filters.locations?.length
        ? true
        : contact.location && filters.locations.includes(contact.location.split(',')[0]);

      return matchesSearch && matchesOrganizations && matchesLocations;
    });

    if (sort.field && sort.direction) {
      filtered = [...filtered].sort((a, b) => {
        let compareA: string | number | undefined;
        let compareB: string | number | undefined;

        switch (sort.field) {
          case 'name':
            compareA = a.firstName ? `${a.firstName} ${a.lastName}` : a.emails[0];
            compareB = b.firstName ? `${b.firstName} ${b.lastName}` : b.emails[0];
            break;
          case 'organization':
            compareA = organizationsById[a.currentOrganizationId]?.name;
            compareB = organizationsById[b.currentOrganizationId]?.name;
            break;
          case 'title':
            compareA = a.currentTitle;
            compareB = b.currentTitle;
            break;
          case 'location':
            compareA = a.location?.split(',')[0];
            compareB = b.location?.split(',')[0];
            break;
          case 'lastMeeting':
            compareA = a.lastMeeting?.toMillis() as number;
            compareB = b.lastMeeting?.toMillis() as number;
            break;
        }

        if (!compareA) return 1;
        if (!compareB) return -1;

        const multiplier = sort.direction === 'asc' ? 1 : -1;
        return compareA < compareB ? -1 * multiplier : multiplier;
      });
    }

    return filtered;
  }, [contacts, filters, sort, organizationsById]);
};

export default useFilteredContacts;
