import { useQuery } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { PointOfInterest } from '../types';
import { transformPointOfInterestResponse } from '../utils/transformPointOfInterestResponse';

const fetchPointsOfInterest = async (): Promise<PointOfInterest[]> => {
  const { data } = await axios.get('/location/points-of-interest');
  return data.map(transformPointOfInterestResponse);
};

export const usePointsOfInterest = () => {
  return useQuery<PointOfInterest[]>({
    queryKey: ['pointsOfInterest'],
    queryFn: fetchPointsOfInterest,
  });
};
