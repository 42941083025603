import { FC, useCallback } from 'react';

import { Stack, TagsInput, Textarea, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedCallback } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

import { useUpdateCodewordTemplate } from '@features/codeword-templates/api/updateCodewordTemplate';
import { CodewordTemplate } from '@features/codeword-templates/types';
import { usePrompt } from '@hooks/usePrompt';

interface CodewordsFormProps {
  initialCodewordTemplate: CodewordTemplate;
}

export const CodewordsForm: FC<CodewordsFormProps> = ({ initialCodewordTemplate }) => {
  const { mutate: updateCodewordTemplate } = useUpdateCodewordTemplate({
    onSuccess: () => {
      notifications.show({
        title: 'Codeword template updated successfully',
        message: 'Your codeword template has been updated',
        color: 'green',
      });
      form.resetDirty();
      form.clearErrors();
    },
  });
  const updateTemplate = useCallback(
    ({ description, keywords, name }: { description: string; keywords: string[]; name: string }) => {
      updateCodewordTemplate({
        id: initialCodewordTemplate.id,
        name,
        description,
        keywords,
      });
    },
    [updateCodewordTemplate, initialCodewordTemplate.id],
  );

  const debouncedUpdateTemplate = useDebouncedCallback(updateTemplate, 1500);
  const form = useForm({
    initialValues: {
      name: initialCodewordTemplate.name,
      description: initialCodewordTemplate.description,
      keywords: initialCodewordTemplate.keywords,
    },
    validate: {
      name: (value) => (value.trim().length > 0 ? null : 'Name is required'),
      description: (value) => (value.trim().length > 0 ? null : 'Description is required'),
    },
    onValuesChange: (values) => {
      form.validate();
      if (form.isValid()) {
        debouncedUpdateTemplate(values);
      }
    },
    validateInputOnChange: true,
  });
  usePrompt({
    when: form.isDirty(),
    message: 'Are you sure you want to leave this page? You have unsaved changes.',
  });

  return (
    <form>
      <Stack gap="md" w={500}>
        <TextInput label="Name" description="Enter a name for your template." {...form.getInputProps('name')} />
        <TagsInput
          label="Codewords"
          description="Enter one or more words / phrases to trigger this template."
          {...form.getInputProps('keywords')}
          onChange={(value) => {
            form.setFieldValue('keywords', value);
          }}
        />
        <Textarea
          label="Description"
          // eslint-disable-next-line max-len
          description="Describe the scenario where this codewords template will be used. When you use a codeword, it will only activate when the context of the email matches the description below."
          {...form.getInputProps('description')}
          autosize
          minRows={4}
        />
      </Stack>
    </form>
  );
};
