import { Card, Group, Stack, Title } from '@mantine/core';

import { BlockitLogo } from '@components/BlockitLogo';
import { FirebaseLogin } from '@features/auth';

import { PrivacyDisclaimer } from './PrivacyDisclaimer';

export const LoginCard = () => {
  return (
    <Card padding="xl" radius="lg" shadow="md">
      <Stack>
        <Group justify="center" gap="xs" mb="sm">
          <BlockitLogo size="35" />
          <Title order={1} ta="center" c="blockitBlue" size="h2" fw={600}>
            Blockit
          </Title>
        </Group>
        <FirebaseLogin />
        <PrivacyDisclaimer />
      </Stack>
    </Card>
  );
};
