import React from 'react';

import { Flex, Group, Text } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';

import { TooltipIcon } from '@components/TooltipIcon';

import { Attendance, Attendee } from '../types';

import { AttendeeDisplay } from './AttendeeDisplay';

interface AttendeeListProps {
  attendees: Attendee[];
}

export const AttendeeList: React.FC<AttendeeListProps> = ({ attendees }) => {
  if (!attendees || attendees.length === 0) {
    return null;
  }

  const attendeesWithoutSchedulingUser = attendees.filter((attendee) => !attendee.isSchedulingUser);

  const requiredAttendees = attendeesWithoutSchedulingUser.filter(
    (attendee) => attendee.attendance === Attendance.REQUIRED,
  );
  const optionalAttendees = attendeesWithoutSchedulingUser.filter(
    (attendee) => attendee.attendance === Attendance.OPTIONAL,
  );

  return (
    <Group gap="xs" align="flex-start">
      <TooltipIcon title="Attendees" icon={<IconUsers size={16} />} />
      <Flex>
        {requiredAttendees.map((attendee, index) => (
          <React.Fragment key={attendee.email}>
            {index > 0 && (
              <Text size="sm" mr="xs">
                ,
              </Text>
            )}
            <AttendeeDisplay attendee={attendee} />
          </React.Fragment>
        ))}
      </Flex>
      {optionalAttendees.length > 0 && (
        <Flex>
          <Text size="sm" c="dimmed" mr="xs">
            Optional:
          </Text>
          {optionalAttendees.map((attendee, index) => (
            <React.Fragment key={attendee.email}>
              {index > 0 && (
                <Text size="sm" mr="xs">
                  ,
                </Text>
              )}
              <AttendeeDisplay attendee={attendee} />
            </React.Fragment>
          ))}
        </Flex>
      )}
    </Group>
  );
};
