import { DayOfWeek } from '@/types';

import { FlexibleEvent, Frequency } from '../types';

const dayOfWeekToString = (day: DayOfWeek): string => {
  const days: Record<DayOfWeek, string> = {
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
    '7': 'Sunday',
  };
  return days[day];
};

const getOrdinalSuffix = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getRecurrenceText = (event: FlexibleEvent): string => {
  const { frequency, interval, byDay, byMonthDay } = event.recurrance;

  // Handle absolute day of week (e.g., "every Monday")
  if (frequency === Frequency.WEEKLY && byDay?.length === 1) {
    if (interval === 1) {
      return `repeats every ${dayOfWeekToString(byDay[0])}`;
    }
    return `repeats every ${interval} weeks on ${dayOfWeekToString(byDay[0])}`;
  }

  // Handle absolute day of month (e.g., "every 15th of the month")
  if (frequency === Frequency.MONTHLY && byMonthDay?.length === 1) {
    if (interval === 1) {
      return `repeats on the ${getOrdinalSuffix(byMonthDay[0])} of every month`;
    }
    return `repeats on the ${getOrdinalSuffix(byMonthDay[0])} every ${interval} months`;
  }

  // Handle regular intervals
  const getFrequencyText = (freq: Frequency, count: number): string => {
    switch (freq) {
      case Frequency.DAILY:
        return count === 1 ? 'day' : 'days';
      case Frequency.WEEKLY:
        return count === 1 ? 'week' : 'weeks';
      case Frequency.MONTHLY:
        return count === 1 ? 'month' : 'months';
      case Frequency.YEARLY:
        return count === 1 ? 'year' : 'years';
    }
  };

  if (interval === 1) {
    return `repeats every ${getFrequencyText(frequency, interval)}`;
  }

  return `repeats every ${interval} ${getFrequencyText(frequency, interval)}`;
};
