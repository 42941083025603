import { useQuery } from '@tanstack/react-query';

import { TimeWindow } from '@/types';
import { axios } from '@lib/axios';
import { convertToTimeWindowResponse } from '@utils/transformTimeWindowResponse';

import { LocationOverride, LocationOverrideResponse } from '../types';
import { transformLocationOverrideResponse } from '../utils/transformLocationOverride';

const fetchLocationOverrides = async (timeWindow: TimeWindow): Promise<LocationOverride[]> => {
  const { data } = await axios.get('/location/location-overrides', {
    params: {
      ...convertToTimeWindowResponse(timeWindow),
    },
  });
  return data.map((locationOverride: LocationOverrideResponse) => {
    return transformLocationOverrideResponse(locationOverride);
  });
};
export const useLocationOverrides = (timeWindow: TimeWindow) => {
  return useQuery<LocationOverride[]>({
    queryKey: ['locationOverrides'],
    queryFn: () => fetchLocationOverrides(timeWindow),
    retry: false,
  });
};
