import { FC } from 'react';

import { Box, Container, Group, Select, Space, Stack } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';

import { LinkInfo } from '@/types';
import { getActiveLink } from '@utils/getActiveLink';

import { NavLink } from './NavLink';
import { PageTitle } from './PageTitle';
import classes from './SideContent.module.css';

interface SideContentProps {
  children: React.ReactNode;
  sideContent: React.ReactNode;
  subLinks?: LinkInfo[];
  sideContentSize?: string;
}

/**
 * Renders content to the left side of the children on medium+ screens and
 * renders content above in small screens.
 */
export const SideContent: FC<SideContentProps> = (props) => {
  const { children, sideContent, subLinks, sideContentSize } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const activeLink = getActiveLink(subLinks?.map((link) => link.to) || [], location.pathname);

  const leftContentWidth = sideContentSize ?? '200';

  return (
    <>
      <Group visibleFrom="xl" align="stretch" h="100%" grow wrap="nowrap">
        <Stack maw={leftContentWidth} miw={leftContentWidth}>
          <PageTitle />
          <Box>{sideContent}</Box>
          <Box visibleFrom="xl">
            {subLinks?.map((link) => (
              <NavLink key={link.to} label={link.label} to={link.to} active={link.to === activeLink} />
            ))}
          </Box>
          <Box h="100%">
            {subLinks && (
              <Select
                clearable={false}
                data={subLinks.map((link) => ({ label: link.label, value: link.to }))}
                allowDeselect={false}
                value={activeLink}
                hiddenFrom="md"
                onChange={(value) => (value ? navigate(value) : null)}
                size="md"
              />
            )}
          </Box>
        </Stack>
        <Box mt="xs" maw="100%" h="100%" className={classes.childrenContainer}>
          <Container size="xl" ml={0} pb="lg">
            {children}
          </Container>
        </Box>
      </Group>
      <Stack hiddenFrom="xl" gap="xs">
        <PageTitle />
        <Box>
          {subLinks && (
            <Select
              clearable={false}
              data={subLinks.map((link) => ({ label: link.label, value: link.to }))}
              allowDeselect={false}
              value={activeLink}
              hiddenFrom="md"
              onChange={(value) => (value ? navigate(value) : null)}
              size="md"
            />
          )}
        </Box>
        <Box>{sideContent}</Box>
        <Box>
          {children}
          <Space h="xl" />
        </Box>
      </Stack>
    </>
  );
};
