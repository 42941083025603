import { useState } from 'react';

import { Button, Loader, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { SideContent } from '@components/SideContent';

import { useCodewordTemplates } from '../api/getCodewordTemplates';

import { CodewordTemplateList } from './CodewordTemplateList';
import { CreateCodewordTemplateModal } from './CreateCodewordTemplateModal';
export const CodewordTemplatePage = () => {
  const { isLoading: isLoadingCodewordTemplates, data: codewordTemplates } = useCodewordTemplates();
  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

  if (isLoadingCodewordTemplates) return <Loader />;
  if (!codewordTemplates) return <Text c="dimmed">No codeword templates found</Text>;
  return (
    <SideContent
      sideContent={
        <>
          <Text c="dimmed">Create custom templates that are triggered via codewords.</Text>
          <Button mt="md" fullWidth onClick={() => setIsCreateModalOpened(true)} leftSection={<IconPlus size={16} />}>
            Add template
          </Button>
        </>
      }
    >
      <CodewordTemplateList codewordTemplates={codewordTemplates} />
      <CreateCodewordTemplateModal opened={isCreateModalOpened} setOpened={setIsCreateModalOpened} />
    </SideContent>
  );
};
