import { DateTime } from 'luxon';

export enum Attendance {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  EXCLUDE = 'exclude',
}

export interface Attendee {
  email: string;
  name?: string; // Name of the attendee.
  domain: string;
  attendance: Attendance;
  isSchedulingUser: boolean;
  linkedInUrl?: string;
}

export interface MeetingForDisplay {
  id: string;
  startTime: DateTime;
  endTime: DateTime;
  threadSummary: string | null;
  codewordTemplateName: string | null;
  title: string | null;
  attendees: Attendee[];
  location: string | null;
}

export interface MeetingsResponse {
  meetings: MeetingForDisplay[];
  totalPages: number;
  currentPage: number;
}

export interface MeetingsParams {
  start: string;
  end: string;
  page?: string;
  pageSize?: string;
  order?: 'ASC' | 'DESC';
}

export interface InSchedulingMeeting {
  id: string;
  emailSubject: string;
  threadSummary: string | null;
  codewordTemplateName: string | null;
  attendees: Attendee[];
  createdAt: DateTime;
  updatedAt: DateTime;
  numberOfDaysUntilNudge: number | null;
  isWaitingForSidechainResponse: boolean;
}
