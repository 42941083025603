import { DateTime } from 'luxon';

import { TimeWindow, TimeWindowResponse } from '@/types';

export const transformTimeWindowResponse = (timeWindow: TimeWindowResponse): TimeWindow => {
  return {
    start: DateTime.fromISO(timeWindow.start),
    end: DateTime.fromISO(timeWindow.end),
  };
};

export const convertToTimeWindowResponse = (timeWindow: TimeWindow): TimeWindowResponse => {
  return {
    start: timeWindow.start.toISO() || '',
    end: timeWindow.end.toISO() || '',
  };
};
