import { useQuery } from '@tanstack/react-query';

import { useFirebaseAuthState } from '@features/auth';
import { axios } from '@lib/axios';

import { User } from '../types';

const getUser = async (): Promise<User> => {
  const { data } = await axios.get('/user/fetch', { params: { silent: true } });
  return data.user;
};

export const useUser = () => {
  const { user } = useFirebaseAuthState();
  return useQuery({ queryKey: ['user', user?.uid], queryFn: getUser, enabled: !!user?.uid });
};
