import React from 'react';

import { Anchor, Box, Group, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { DayOfWeek } from '@/types';
import { TooltipActionIcon } from '@components/TooltipActionIcon';
import { DAY_OF_WEEK_LABELS } from '@constants/index';

import { WorkingHour } from '../types';

import { CopyWorkingHoursDropdown } from './CopyWorkingHoursDropdown';
import { WorkingHourTimeInput } from './WorkingHourTimeInput';

interface WorkingHoursForDayProps {
  day: DayOfWeek;
  workHours: WorkingHour[];
  errors?: string | null;
  onAddWorkingHours: (day: DayOfWeek) => void;
  onWorkingHourChange: (day: DayOfWeek, index: number, start: string, end: string) => void;
  onRemoveWorkingHours: (day: DayOfWeek, index: number) => void;
  onAddWorkingHoursSlot: (day: DayOfWeek) => void;
  onCopyWorkingHours: (currentDay: DayOfWeek, daysToCopy: DayOfWeek[]) => void;
}

export const WorkingHoursForDay: React.FC<WorkingHoursForDayProps> = ({
  day,
  workHours,
  errors,
  onAddWorkingHours,
  onWorkingHourChange,
  onRemoveWorkingHours,
  onAddWorkingHoursSlot,
  onCopyWorkingHours,
}) => {
  if (!workHours || workHours.length === 0) {
    return (
      <Box>
        <Text size="md" fw={600} w={100}>
          {DAY_OF_WEEK_LABELS[day]}
        </Text>
        <Anchor component="button" size="xs" onClick={() => onAddWorkingHours(day)}>
          + Add meeting hours
        </Anchor>
      </Box>
    );
  }

  return (
    <Box w={285}>
      <Group justify="space-between" mb="xs">
        <Text size="md" fw={600}>
          {DAY_OF_WEEK_LABELS[day]}
        </Text>
        <Group>
          <TooltipActionIcon
            onClick={() => onAddWorkingHoursSlot(day)}
            size="xs"
            icon={<IconPlus size={16} />}
            title="Add another slot"
          />
          <CopyWorkingHoursDropdown
            currentDayIndex={day}
            onSubmit={(daysToCopy) => onCopyWorkingHours(day, daysToCopy)}
          />
        </Group>
      </Group>
      {workHours.map(([start, end], index) => (
        <WorkingHourTimeInput
          key={index}
          day={day}
          index={index}
          start={start}
          end={end}
          onWorkingHourChange={onWorkingHourChange}
          onRemoveWorkingHours={onRemoveWorkingHours}
        />
      ))}

      {errors && (
        <Text size="sm" c="red">
          {errors}
        </Text>
      )}
    </Box>
  );
};
