import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AnalyticsEventName, track } from '@features/analytics';
import { RequestError, axios } from '@lib/axios';

import { LocationOverride, LocationOverrideResponse } from '../types';
import { transformLocationOverrideResponse } from '../utils/transformLocationOverride';

const syncLocationOverrides = async (): Promise<LocationOverride[]> => {
  const response = await axios.post('/location/location-overrides/sync', {});
  return response.data.map((locationOverride: LocationOverrideResponse) =>
    transformLocationOverrideResponse(locationOverride),
  );
};

export const useSyncLocationOverrides = () => {
  const queryClient = useQueryClient();
  return useMutation<LocationOverride[], RequestError>({
    mutationFn: syncLocationOverrides,
    onSuccess: (locationOverrides) => {
      if (locationOverrides.length > 0) {
        notifications.show({
          message: `Found ${locationOverrides.length} location override${locationOverrides.length > 1 ? 's' : ''}`,
          color: 'green',
          autoClose: 3000,
        });
      } else {
        notifications.show({
          message: 'No location overrides found. Add location overrides manually.',
          color: 'yellow',
          autoClose: 3000,
        });
      }
      track({
        type: AnalyticsEventName.LocationOverridesSynced,
        data: { locationOverridesFound: locationOverrides.length },
      });
      queryClient.refetchQueries({ queryKey: ['locationOverrides'] });
    },
  });
};
