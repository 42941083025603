import React, { useEffect, useState } from 'react';

import { Center, Group, Pagination, Stack, Text } from '@mantine/core';
import { DateTime } from 'luxon';

import { useMeetings } from '../api/getMeetings';
import { MeetingForDisplay } from '../types';

import { MeetingList } from './MeetingList';

interface GroupedMeetingsProps {
  start: DateTime;
  end: DateTime;
  order?: 'ASC' | 'DESC';
}

const PAGE_SIZE = 10;

export const GroupedMeetings: React.FC<GroupedMeetingsProps> = ({ start, end, order }) => {
  const [activePage, setActivePage] = useState(1);

  const { data, isLoading, error } = useMeetings({
    start: start.toISO() as string,
    end: end.toISO() as string,
    page: activePage.toString(),
    pageSize: PAGE_SIZE.toString(),
    order: order || 'DESC',
  });

  useEffect(() => {
    setActivePage(1);
  }, [start, end]);

  if (isLoading) return <Text>Loading...</Text>;
  if (error) return <Text>Error loading meetings</Text>;
  if (!data) return null;

  const { meetings, totalCount } = data;

  const groupedMeetings = meetings.reduce(
    (acc, meeting) => {
      const date = meeting.startTime.toFormat('EEEE, MMMM d');
      if (!acc[date]) acc[date] = [];
      acc[date].push(meeting);
      return acc;
    },
    {} as Record<string, MeetingForDisplay[]>,
  );
  const totalPages = Math.ceil(totalCount / PAGE_SIZE);

  return (
    <Stack gap="md" mt="md">
      {Object.entries(groupedMeetings).length === 0 ? (
        <Text>No meetings found.</Text>
      ) : (
        <>
          {Object.entries(groupedMeetings).map(([date, dateMeetings]) => (
            <Stack key={date}>
              <Text fw={700}>{date}</Text>
              <MeetingList meetings={dateMeetings} />
            </Stack>
          ))}
          {totalPages > 1 && (
            <Center>
              <Group>
                <Text size="sm">Total: {totalCount}</Text>
                <Pagination total={totalPages} value={activePage} onChange={setActivePage} />
              </Group>
            </Center>
          )}
        </>
      )}
    </Stack>
  );
};
