import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

const deletePointOfInterest = async (pointOfInterestId: string): Promise<void> => {
  await axios.delete(`/location/points-of-interest/${pointOfInterestId}`);
};

export const useDeletePointOfInterest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePointOfInterest,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['anchorAvailabilities'],
      });
      queryClient.refetchQueries({
        queryKey: ['pointsOfInterest'],
      });
      queryClient.refetchQueries({
        queryKey: ['locationOverrides'],
      });
    },
  });
};
