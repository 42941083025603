import { FC, ReactNode } from 'react';

import { Center } from '@mantine/core';

import { GradientBackground } from './GradientBackground';

interface PageContainerProps {
  children: ReactNode;
}

export const PageContainer: FC<PageContainerProps> = ({ children }) => {
  return (
    <GradientBackground>
      <Center h="100vh">{children}</Center>
    </GradientBackground>
  );
};
