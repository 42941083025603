import { useCallback, useEffect } from 'react';

import { Loader } from '@mantine/core';
import { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { PageContainer } from '@components/PageContainer';
import { useLogPageView } from '@features/analytics';
import { PUBLIC_ROUTES } from '@routes/enums';

import { useConnectAccount } from '../api/connectAccount';
import { useFirebaseAuthState } from '../hooks/useFirebaseAuthState';

/**
 * The Google OAuth popup redirects to this page after the user authorizes
 * a calendar.
 */
export const MicrosoftOAuthPage = () => {
  const authState = useFirebaseAuthState();
  const [searchParams] = useSearchParams();
  const { mutateAsync: connectAccount } = useConnectAccount();
  useLogPageView('Microsoft OAuth');

  const checkAuthorization = useCallback(async () => {
    if (!authState.uid) {
      return;
    }

    try {
      const code = searchParams.get('code');
      const userIdAndVerifierFromState = searchParams.get('state');

      if (!code || !userIdAndVerifierFromState) {
        throw new Error('missing code or state');
      }

      const [userIdFromState, verifier] = userIdAndVerifierFromState.split('#');

      if (userIdFromState !== authState.uid) {
        throw new Error('user id state mismatch');
      }

      await connectAccount({
        userId: userIdFromState,
        code,
        redirectUrl: `${window.location.origin}${PUBLIC_ROUTES.MICROSOFT_OAUTH}`,
        type: 'microsoft',
        codeVerifier: verifier,
      });

      // Send a message that the account was added so that we know when this page closes
      window.opener.postMessage({ type: 'microsoft-account-added', code }, window.location.origin);
      window.close();
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data) {
        window.opener.postMessage(
          { type: 'microsoft-account-added-error', message: err.response.data.message },
          window.location.origin,
        );
      } else {
        window.opener.postMessage(
          { type: 'microsoft-account-added-error', message: 'Unable to add account. Please try again.' },
          window.location.origin,
        );
      }
    }
  }, [authState.uid, connectAccount, searchParams]);

  useEffect(() => {
    checkAuthorization();
  }, [checkAuthorization]);

  return (
    <PageContainer>
      <Loader size={30} />
    </PageContainer>
  );
};
