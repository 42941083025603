import { getFirstAndLastName } from '../../../utils/nameUtil';
import { GoogleCalendarEvent } from '../api/googleCalendar';

const MIN_EXT_MEETING_FOR_ONBOARDING = 20;
const BOOKING_LINK_RATIO_THRESHOLD = 0.5;
const NUM_WEEKS_IN_MONTH = 4;

export interface CalendarStats {
  numExternalMeetings: number;
  numTotalMeetings: number;
  numTotalMeetingsCreated: number;
  numExternalMeetingsCreated: number;
  numMeetingsCreatedByLinks: number;
  numHolds: number;
}

export const computeMeetingStats = (events: GoogleCalendarEvent[], userEmail: string): CalendarStats => {
  const nonAllDayEvents = events.filter((event) => event.start.dateTime && event.end.dateTime);
  const numHolds = nonAllDayEvents.filter((event) => !event.attendees || event.attendees.length === 0).length;
  const meetings = nonAllDayEvents.filter((event) => event.attendees && event.attendees.length > 1);
  const meetingsCreatedByUser = nonAllDayEvents.filter(
    (event) => event.attendees && event.attendees.length > 1 && event.creator.email === userEmail,
  );
  const numTotalMeetings = meetings.length;
  const numTotalMeetingsCreated = meetingsCreatedByUser.length;

  const userDomain = userEmail.split('@')[1];
  const isExternalMeeting = (meeting: GoogleCalendarEvent) => {
    return meeting.attendees?.some((attendee) => {
      const attendeeDomain = attendee.email.split('@')[1];
      return attendeeDomain !== userDomain;
    });
  };
  const numExternalMeetings = meetings.filter((meeting) => {
    return isExternalMeeting(meeting);
  }).length;
  const externalMeetingsCreatedByUser = meetingsCreatedByUser.filter(isExternalMeeting);
  const numExternalMeetingsCreatedByUser = externalMeetingsCreatedByUser.length;

  const numMeetingsCreatedByLinks = externalMeetingsCreatedByUser.filter((ev) => {
    const description = ev.description ?? '';
    return (
      description.includes('calendly.com/reschedulings') ||
      description.includes('hubspot.com/meetings') ||
      description.includes('cal.com') ||
      description.includes('zcal.co')
    );
  }).length;

  return {
    numExternalMeetings,
    numTotalMeetings,
    numExternalMeetingsCreated: numExternalMeetingsCreatedByUser,
    numTotalMeetingsCreated,
    numMeetingsCreatedByLinks,
    numHolds,
  };
};

export const countExternalMeetings = (events: GoogleCalendarEvent[], userEmail: string): number => {
  const userDomain = userEmail.split('@')[1];

  return events.filter((event) => {
    // Check if it's not an all-day event
    const isNotAllDay = event.start.dateTime && event.end.dateTime;

    // Check if there are attendees and more than one
    const hasMultipleAttendees = event.attendees && event.attendees.length > 1;

    // Check if there's at least one attendee with a different domain
    const hasDifferentDomain = event.attendees?.some((attendee) => {
      const attendeeDomain = attendee.email.split('@')[1];
      return attendeeDomain !== userDomain;
    });

    return isNotAllDay && hasMultipleAttendees && hasDifferentDomain;
  }).length;
};

export const parseJwtToProfile = (idToken: string): { email: string; firstName: string; lastName: string } | null => {
  try {
    const base64Url = idToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );

    const decodedToken = JSON.parse(jsonPayload);

    // Check if the JWT has the expected structure
    if (decodedToken && decodedToken.email) {
      const { firstName, lastName } = decodedToken.name
        ? getFirstAndLastName(decodedToken.name)
        : { firstName: decodedToken.given_name, lastName: decodedToken.family_name };
      return {
        email: decodedToken.email,
        firstName,
        lastName,
      };
    }

    return null;
  } catch (error) {
    return null;
  }
};

const getPercentileText = (eventStats: CalendarStats): string => {
  // Based on the percentile of external meetings per week from our calendar analytics.
  // https://blockit.retool.com/querylibrary/1197803/ext-meeting-count-percentile
  // TODO: we can perhaps fetch this from live data from retool
  const meetingsPerWeek = Math.ceil(eventStats.numTotalMeetings / NUM_WEEKS_IN_MONTH);
  let meetingText = `On average, you take ${meetingsPerWeek} ` + `meetings per week,`;
  if (meetingsPerWeek >= 44) {
    meetingText += ' which is 99th percentile among Blockit users!';
  } else if (meetingsPerWeek >= 34) {
    meetingText += ' which is 95th percentile among Blockit users!';
  } else if (meetingsPerWeek >= 28) {
    meetingText += ' which is 90th percentile among Blockit users!';
  } else if (meetingsPerWeek >= 24) {
    meetingText += ' which is 85th percentile among Blockit users!';
  } else if (meetingsPerWeek >= 21) {
    meetingText += ' which is 80th percentile among Blockit users!';
  } else if (meetingsPerWeek >= 19) {
    meetingText += ' which is 75th percentile among Blockit users!';
  } else if (meetingsPerWeek >= 16) {
    meetingText += ' which is 70th percentile among Blockit users!';
  } else if (meetingsPerWeek >= 9) {
    meetingText += ' which puts you in the top half of Blockit users!';
  } else {
    meetingText += ' which is below average among Blockit users!';
  }

  const extMeetingPercentage = Math.ceil((eventStats.numExternalMeetings / eventStats.numTotalMeetings) * 100);
  meetingText += ` Typically, ${extMeetingPercentage}% of your meetings are external.`;

  return meetingText;
};

export const getCalendarStatsText = (eventStats: CalendarStats): string[] => {
  const lines: string[] = [];

  lines.push(getPercentileText(eventStats));
  if (isQualifiedUser(eventStats)) {
    lines.push(`You're almost certainly wasting too much time scheduling!`);
  }

  return lines;
};

export const isQualifiedUser = (eventStats: CalendarStats): boolean => {
  return (
    eventStats.numExternalMeetingsCreated >= MIN_EXT_MEETING_FOR_ONBOARDING &&
    eventStats.numMeetingsCreatedByLinks / eventStats.numExternalMeetingsCreated < BOOKING_LINK_RATIO_THRESHOLD
  );
};
