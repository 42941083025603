import { FC } from 'react';

import { Switch as MantineSwitch, SwitchProps } from '@mantine/core';

import classes from './Switch.module.css';

/**
 * Wrapper around mantine switch to provide pointer cursor on hover.
 */
export const Switch: FC<SwitchProps> = (props) => {
  return <MantineSwitch {...props} classNames={{ track: classes.switch }} />;
};
