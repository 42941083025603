import { Anchor, Text } from '@mantine/core';

export const PrivacyDisclaimer = () => {
  return (
    <Text c="dimmed" size="xs">
      By continuing, you agree to our{' '}
      <Anchor href="https://www.blockit.com/terms-policy" target="_blank">
        Terms of Service
      </Anchor>
      .
    </Text>
  );
};
