import { FC } from 'react';

import { Badge, Card, Group, Stack, Text, Title } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import { TooltipActionIcon } from '@components/TooltipActionIcon';
import { formatDuration } from '@utils/dateTime';

import { useDeleteCodewordTemplate } from '../api/deleteCodewordTemplate';
import { CodewordTemplate } from '../types';

import { DifferentPreferences } from './DifferentPreferences';

interface CodewordTemplateCardProps {
  codewordTemplate: CodewordTemplate;
}

export const CodewordTemplateCard: FC<CodewordTemplateCardProps> = ({ codewordTemplate }) => {
  const { mutate: deleteCodewordTemplate } = useDeleteCodewordTemplate();

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Group justify="space-between">
          <Title order={3}>{codewordTemplate.name}</Title>
          <Text c="dimmed" size="md">
            {formatDuration(codewordTemplate.preferenceSettings.duration, true)}
          </Text>
        </Group>
      </Card.Section>
      <Group align="flex-end" justify="space-between">
        <Stack mt="md">
          {codewordTemplate.description ? (
            <Text size="md">{codewordTemplate.description}</Text>
          ) : (
            <Text size="md" fs="italic" c="dimmed">
              Add a description to tell Blockit when to use this template
            </Text>
          )}
          {codewordTemplate.keywords.length > 0 && (
            <Group gap="xs">
              {codewordTemplate.keywords.map((keyword, index) => (
                <Badge key={index} color="blue" variant="light">
                  {keyword}
                </Badge>
              ))}
            </Group>
          )}
          <DifferentPreferences newPreferences={codewordTemplate.preferenceSettings} />
        </Stack>
        <Group justify="flex-end">
          <TooltipActionIcon
            size="lg"
            color="black"
            icon={<IconTrash size={20} />}
            onClick={() => deleteCodewordTemplate(codewordTemplate.id)}
          />
          <TooltipActionIcon
            size="lg"
            color="black"
            icon={<IconEdit size={20} />}
            component={Link}
            to={codewordTemplate.id}
          />
        </Group>
      </Group>
    </Card>
  );
};
