import { type User } from 'firebase/auth';

import { axios } from '@lib/axios';

export const login = async (authedUser: User) => {
  return await axios.post(`/user/login`, {
    userId: authedUser.uid,
    phone: authedUser.phoneNumber,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};
