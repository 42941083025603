import { useQuery } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { PreferenceSettings } from '../types';

const getPreferences = async (): Promise<PreferenceSettings> => {
  const { data } = await axios.get('/preferences');
  return data.preferences;
};

export const usePreferences = () => useQuery({ queryKey: ['preferences'], queryFn: getPreferences });
