import { FC, useState } from 'react';

import { Group, Radio, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconClockPause } from '@tabler/icons-react';

import { Switch } from '@components/Switch';
import { AnalyticsEventName, track } from '@features/analytics';

import { useUpdatePreferences } from '../api/updatePreferences';
import { ConsecutiveMeetingMinutesBuffer, PreferenceSettings } from '../types';

import { MinutesSelection } from './MinutesSelection';
import { SettingsSectionTitle } from './SettingsSectionTitle';

enum MeetingBufferType {
  EveryMeeting = 'everyMeeting',
  ConsecutiveMinutes = 'consecutiveMinutes',
}

interface MeetingBuffersProps {
  initialPreferences: PreferenceSettings;
}

export const MeetingBuffers: FC<MeetingBuffersProps> = ({ initialPreferences }) => {
  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'meetingBuffers', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: 'Buffers updated', color: 'green' });
    },
  });

  const [areMeetingBreaksEnabled, setAreMeetingBreaksEnabled] = useState(
    Boolean(initialPreferences.everyMeetingBufferInMinutes || initialPreferences.consecutiveMeetingBuffer),
  );

  const [meetingBufferType, setMeetingBufferType] = useState(
    initialPreferences.everyMeetingBufferInMinutes
      ? MeetingBufferType.EveryMeeting
      : MeetingBufferType.ConsecutiveMinutes,
  );

  const [everyMeetingBufferInMinutes, setEveryMeetingBufferInMinutes] = useState(
    initialPreferences.everyMeetingBufferInMinutes || 15,
  );
  const [consecutiveMeetingBuffer, setConsecutiveMeetingBuffer] = useState<ConsecutiveMeetingMinutesBuffer>(
    initialPreferences.consecutiveMeetingBuffer || { durationInMinutes: 15, maxMeetingMinutes: 180 },
  );
  const handleConsecutiveMeetingBufferDurationSelect = (value: number) => {
    setConsecutiveMeetingBuffer({
      ...consecutiveMeetingBuffer,
      durationInMinutes: value,
    });

    if (meetingBufferType !== MeetingBufferType.ConsecutiveMinutes) return;
    updatePreferences({
      ...initialPreferences,
      // we want to make sure that everyMeetingBufferInMinutes is not set if consecutiveMeetingBuffer is set
      everyMeetingBufferInMinutes: undefined,
      consecutiveMeetingBuffer: {
        maxMeetingMinutes: consecutiveMeetingBuffer.maxMeetingMinutes,
        durationInMinutes: value,
      },
    });
  };

  const handleConsecutiveMeetingBufferMaxMinutesSelect = (value: number) => {
    setConsecutiveMeetingBuffer({
      ...consecutiveMeetingBuffer,
      maxMeetingMinutes: value,
    });

    if (meetingBufferType !== MeetingBufferType.ConsecutiveMinutes) return;
    updatePreferences({
      ...initialPreferences,
      // we want to make sure that everyMeetingBufferInMinutes is not set if consecutiveMeetingBuffer is set
      everyMeetingBufferInMinutes: undefined,
      consecutiveMeetingBuffer: {
        durationInMinutes: consecutiveMeetingBuffer.durationInMinutes,
        maxMeetingMinutes: value,
      },
    });
  };

  const handleEveryMeetingBufferSelect = (value: number) => {
    setEveryMeetingBufferInMinutes(value);
    if (meetingBufferType !== MeetingBufferType.EveryMeeting) return;
    updatePreferences({
      ...initialPreferences,
      // we want to make sure that consecutive meeting buffer is not set if everyMeetingBufferInMinutes is set
      consecutiveMeetingBuffer: undefined,
      everyMeetingBufferInMinutes: value,
    });
  };

  const handleMeetingBufferTypeChange = (value: MeetingBufferType) => {
    setMeetingBufferType(value);

    if (value === MeetingBufferType.ConsecutiveMinutes) {
      updatePreferences({
        ...initialPreferences,
        everyMeetingBufferInMinutes: undefined,
        consecutiveMeetingBuffer,
      });
    } else if (value === MeetingBufferType.EveryMeeting) {
      updatePreferences({
        ...initialPreferences,
        consecutiveMeetingBuffer: undefined,
        everyMeetingBufferInMinutes,
      });
    }
  };

  const handleAreMeetingBreaksEnabledChange = () => {
    if (!initialPreferences) return;
    const newValue = !areMeetingBreaksEnabled;
    setAreMeetingBreaksEnabled(newValue);

    if (newValue) {
      handleMeetingBufferTypeChange(meetingBufferType);
    } else {
      updatePreferences({
        ...initialPreferences,
        consecutiveMeetingBuffer: undefined,
        everyMeetingBufferInMinutes: undefined,
      });
    }
  };

  const textColor = areMeetingBreaksEnabled ? undefined : 'gray.4';

  if (!initialPreferences) return null;

  return (
    <Stack>
      <Group>
        <SettingsSectionTitle
          title="Meeting Breaks"
          Icon={IconClockPause}
          // eslint-disable-next-line max-len
          description="By default, Blockit clusters your meetings. To avoid back-to-back scheduling, add meeting breaks. If no other times are available, Blockit may override these breaks."
        />
        <Switch
          checked={areMeetingBreaksEnabled}
          onChange={handleAreMeetingBreaksEnabledChange}
          offLabel="OFF"
          onLabel="ON"
        />
        <Text c="dimmed">
          By default, Blockit clusters your meetings. To avoid back-to-back scheduling, add meeting breaks. If no other
          times are available, Blockit may override these breaks.
        </Text>
      </Group>

      <Radio.Group
        value={meetingBufferType}
        onChange={(value) => handleMeetingBufferTypeChange(value as MeetingBufferType)}
      >
        <Stack>
          <Radio
            styles={{ inner: { alignSelf: 'center' } }}
            value={MeetingBufferType.EveryMeeting}
            disabled={!areMeetingBreaksEnabled}
            label={
              <span>
                <Text span c={textColor}>
                  I want a
                </Text>
                <MinutesSelection
                  initialValue={everyMeetingBufferInMinutes}
                  onSelect={handleEveryMeetingBufferSelect}
                  range={[15, 60]}
                  isInline
                  useSingular
                  disabled={!areMeetingBreaksEnabled}
                />
                <Text span c={textColor}>
                  break after every meeting.
                </Text>
              </span>
            }
          />
          <Radio
            styles={{ inner: { alignSelf: 'center' } }}
            disabled={!areMeetingBreaksEnabled}
            value={MeetingBufferType.ConsecutiveMinutes}
            label={
              <>
                <Text span c={textColor}>
                  I want a
                </Text>
                <MinutesSelection
                  initialValue={consecutiveMeetingBuffer.durationInMinutes}
                  onSelect={handleConsecutiveMeetingBufferDurationSelect}
                  isInline
                  range={[15, 60]}
                  useSingular
                  disabled={!areMeetingBreaksEnabled}
                />
                <Text span c={textColor}>
                  break after
                </Text>
                <MinutesSelection
                  initialValue={consecutiveMeetingBuffer.maxMeetingMinutes}
                  onSelect={handleConsecutiveMeetingBufferMaxMinutesSelect}
                  isInline
                  range={[30, 360]}
                  step={30}
                  disabled={!areMeetingBreaksEnabled}
                />
                <Text span c={textColor}>
                  of consecutive meetings.
                </Text>
              </>
            }
          />
        </Stack>
      </Radio.Group>
    </Stack>
  );
};
