import { Anchor, Group, Popover, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { Contact, Organization } from '../../types';
import { OrganizationDisplay } from '../OrganizationDisplay';

interface OrganizationContentProps {
  contact: Contact;
  filters?: {
    organizationIds?: string[];
  };
  organizationsById: Record<string, Organization>;
}

export const OrganizationContent = ({ contact, filters, organizationsById }: OrganizationContentProps) => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const organization = contact.currentOrganizationId ? organizationsById[contact.currentOrganizationId] : null;

  const organizationIds = filters?.organizationIds ?? [];
  const pastOrganizationIds = contact.pastOrganizationIds?.filter((orgId) => organizationIds.includes(orgId));
  const hasMultipleOrganizations = pastOrganizationIds && pastOrganizationIds.length > 0;
  const noOrganizationsToShow = !hasMultipleOrganizations && !organization;

  return (
    <Stack gap="xs">
      <Group gap={4} wrap="nowrap">
        {organization && <OrganizationDisplay organization={organization} />}
        {hasMultipleOrganizations && (
          <Popover position="bottom" shadow="md" opened={opened} onChange={close} withArrow>
            <Popover.Target>
              <Anchor onClick={toggle} size="xs" fw={700}>{`+${pastOrganizationIds.length}`}</Anchor>
            </Popover.Target>

            <Popover.Dropdown>
              <Stack gap="xs">
                {pastOrganizationIds.map((orgId) => (
                  <OrganizationDisplay key={orgId} organization={organizationsById[orgId]} isPast />
                ))}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        )}
      </Group>
      {noOrganizationsToShow && <Text>-</Text>}
    </Stack>
  );
};
