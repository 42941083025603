import { FC } from 'react';

import { Box, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';

import { AccountWithCalendars } from '../types';

import { AccountCompanyName } from './AccountCompanyName';
import { CalendarDetails } from './CalendarDetails';

interface AccountDetailsProps {
  account: AccountWithCalendars;
}

export const AccountDetails: FC<AccountDetailsProps> = ({ account }) => {
  // Return the calendars that match the account first, then default to alphabetical
  const sortedCalendars = account.calendars.sort((a, b) => {
    if (a.calendarId === account.email) {
      return -1;
    } else if (b.calendarId === account.email) {
      return 1;
    }
    return a.name.localeCompare(b.name);
  });
  return (
    <Group gap="xs" align="flex-start">
      <ThemeIcon color="blue" variant="transparent">
        <IconCalendar />
      </ThemeIcon>
      <Stack gap="xs">
        <Text key={account.email}>{account.email}</Text>
        <AccountCompanyName account={account} />
        <Box>
          <Text size="sm" fw={700} mb="xs">
            Calendars
          </Text>
          {sortedCalendars.map((calendar) => (
            <Box key={calendar.name}>
              <CalendarDetails key={calendar.name} calendar={calendar} accountId={account.email} />
            </Box>
          ))}
        </Box>
      </Stack>
    </Group>
  );
};
