import { MutateOptions, useMutation } from '@tanstack/react-query';

import { axios, RequestError } from '@lib/axios';

interface ConnectZoomAccountRequest {
  code: string;
  redirectUrl: string;
}

const connectZoomAccount = async (request: ConnectZoomAccountRequest): Promise<void> => {
  const { redirectUrl } = request;
  await axios.post(`/zoom/connect`, { code: request.code, redirectUri: redirectUrl });
};

export const useConnectZoomAccount = (options?: MutateOptions<void, RequestError, ConnectZoomAccountRequest>) => {
  return useMutation({
    ...options,
    mutationFn: connectZoomAccount,
  });
};
