import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { AccountWithCalendars, Calendar } from '../types';

interface UpdateCalendarPropertiesParams {
  userId: string;
  accountId: string;
  calendar: Calendar;
  isSelected: boolean;
}

export const updateCalendarProperties = async (params: UpdateCalendarPropertiesParams) => {
  const { calendar, isSelected, userId, accountId } = params;
  await axios.patch(`/account/${userId}/${accountId}/${calendar.calendarId}/patch`, {
    isSelected,
  });
  return true;
};

export const useUpdateCalendar = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCalendarProperties,
    onSuccess: (success, variables) => {
      if (success) {
        queryClient.setQueryData<AccountWithCalendars[]>(
          ['calendarsByAccounts', variables.userId],
          (existingAccounts: AccountWithCalendars[] = []) => {
            return existingAccounts.map((account) => {
              if (account.email !== variables.accountId) return account;
              const calendarToUpdate = account.calendars.find(
                (cal) => cal.calendarId === variables.calendar.calendarId,
              );
              if (calendarToUpdate) calendarToUpdate.isSelected = variables.isSelected;
              return account;
            });
          },
        );
      }
    },
  });
};
