import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { BASE_API_PATH } from '../constants';
import { FlexibleEvent } from '../types';

interface MarkAsFlexibleParams {
  recurringRemoteEventId: string;
}

const markAsFlexible = async ({ recurringRemoteEventId }: MarkAsFlexibleParams): Promise<FlexibleEvent> => {
  const { data } = await axios.post<FlexibleEvent>(`${BASE_API_PATH}/${recurringRemoteEventId}/mark`);
  return data;
};

export const useMarkAsFlexible = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markAsFlexible,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['flexible-events'] });
    },
  });
};
