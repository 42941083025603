import { DateTime } from 'luxon';

import { GooglePlaceInfo } from '@/types';

export interface Organization {
  id: string;
  externalId: string;
  name: string;
  source: string;
  domain: string;
  websiteUrl: string;
  logoUrl: string;
}

export interface Contact {
  id?: string;
  firstName: string;
  lastName: string;
  emails: string[];
  photoUrl?: string;
  currentOrganizationId: string;
  linkedInUrl?: string;
  location?: string;
  lastMeeting?: DateTime;
  currentTitle?: string;
  pastOrganizationIds?: string[];
  keepInTouch?: string;
}

export enum ContactTableColumn {
  NAME = 'name',
  ORGANIZATION = 'organization',
  TITLE = 'title',
  EMAILS = 'emails',
  INFO = 'info',
  LAST_MEETING = 'lastMeeting',
  LOCATION = 'location',
  ACTIONS = 'actions',
}

export type SortField =
  | ContactTableColumn.NAME
  | ContactTableColumn.ORGANIZATION
  | ContactTableColumn.TITLE
  | ContactTableColumn.LOCATION
  | ContactTableColumn.LAST_MEETING;

export type SortDirection = 'asc' | 'desc' | null;

export interface SortState {
  field: SortField | null;
  direction: SortDirection;
}

export interface CreateContactDTO {
  id?: string;
  firstName: string;
  lastName: string;
  emails?: string[];
  currentOrganizationId?: string;
  linkedInUrl?: string;
  location: GooglePlaceInfo | null;
  currentTitle?: string;
}
