import { useMutation } from '@tanstack/react-query';

import { axios } from '@lib/axios';

interface ConnectAccountRequest {
  userId: string;
  code: string;
  redirectUrl: string;
  type?: 'google' | 'microsoft';
  codeVerifier?: string;
}

const connectAccount = async (request: ConnectAccountRequest) => {
  const { redirectUrl, ...otherValues } = request;
  return await axios.post(`/account/connect`, { ...otherValues, redirect_uri: redirectUrl });
};

export const useConnectAccount = () => {
  return useMutation({
    mutationFn: connectAccount,
  });
};
