import { MutateOptions, useMutation, useQueryClient } from '@tanstack/react-query';

import { AnalyticsEventName } from '@/features/analytics/types';
import { track } from '@features/analytics';
import { axios, RequestError } from '@lib/axios';

import { CodewordTemplate } from '../types';

import { API_BASE_URL, QUERY_KEY } from './constants';

interface UpdateCodewordTemplateParams {
  id: string;
  name: string;
  description: string;
  keywords: string[];
}

const updateCodewordTemplate = async (updatedTemplate: UpdateCodewordTemplateParams): Promise<CodewordTemplate> => {
  const { id, ...template } = updatedTemplate;
  const { data } = await axios.put(`${API_BASE_URL}/${id}`, template);
  return data;
};

export const useUpdateCodewordTemplate = (
  options?: MutateOptions<CodewordTemplate, RequestError, UpdateCodewordTemplateParams>,
) => {
  const queryClient = useQueryClient();
  return useMutation<CodewordTemplate, RequestError, UpdateCodewordTemplateParams>({
    mutationFn: updateCodewordTemplate,
    onSuccess: (success, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY, variables.id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

      // Track the update event
      track({
        type: AnalyticsEventName.CodewordTemplateUpdated,
        data: { templateId: success.id, name: success.name },
      });

      options?.onSuccess?.(success, variables, context);
    },
    ...options,
  });
};
