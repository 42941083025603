import { useQuery } from '@tanstack/react-query';

import { axios, RequestError } from '@lib/axios';

import { CodewordTemplate } from '../types';

import { API_BASE_URL, QUERY_KEY } from './constants';

export const useCodewordTemplate = (id: string | undefined) =>
  useQuery<CodewordTemplate, RequestError>({
    queryKey: [QUERY_KEY, id],
    queryFn: async () => {
      const { data } = await axios.get(`${API_BASE_URL}/${id}`, {
        params: {
          silent: true,
        },
      });
      return data;
    },
    enabled: !!id,
    retry: false,
  });
