import { useState } from 'react';

import { Button, Group, MultiSelect, TextInput } from '@mantine/core';
import { IconFilter, IconSearch, IconX } from '@tabler/icons-react';

import { Contact, Organization } from '../types';

export interface ContactFilters {
  search?: string;
  organizationIds?: string[];
  locations?: string[];
}

interface ContactsFiltersProps {
  onFiltersChange: (filters: ContactFilters) => void;
  contacts: Contact[];
  organizations: Organization[];
}

export function ContactsFilters({ onFiltersChange, contacts, organizations }: ContactsFiltersProps) {
  const [search, setSearch] = useState('');
  const [organizationIds, setOrganizationIds] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const uniqueLocations = Array.from(
    new Set(contacts?.map((contact) => contact.location?.split(',')[0]).filter((loc): loc is string => Boolean(loc))),
  );

  const handleReset = () => {
    setSearch('');
    setOrganizationIds([]);
    setLocations([]);
    onFiltersChange({});
    setIsExpanded(false);
  };

  return (
    <Group gap="sm" justify="space-between">
      <TextInput
        placeholder="Search"
        leftSection={<IconSearch size={16} />}
        value={search}
        onChange={(event) => {
          const newSearch = event.currentTarget.value;
          setSearch(newSearch);
          onFiltersChange({
            search: newSearch || undefined,
            organizationIds: organizationIds.length ? organizationIds : undefined,
            locations: locations.length ? locations : undefined,
          });
        }}
        style={{ flex: 1 }}
      />
      {!isExpanded && (
        <Button variant="default" leftSection={<IconFilter size={16} />} onClick={() => setIsExpanded(!isExpanded)}>
          Filter
        </Button>
      )}
      {isExpanded && (
        <Group maw={1000} gap="xs">
          <MultiSelect
            placeholder="Organizations"
            data={organizations.map((org) => ({ label: org.name, value: org.id }))}
            value={organizationIds}
            onChange={(value) => {
              setOrganizationIds(value);
              onFiltersChange({
                search: search || undefined,
                organizationIds: value.length ? value : undefined,
                locations: locations.length ? locations : undefined,
              });
            }}
            searchable
          />
          <MultiSelect
            placeholder="Locations"
            data={uniqueLocations}
            value={locations}
            onChange={(value) => {
              setLocations(value);
              onFiltersChange({
                search: search || undefined,
                organizationIds: organizationIds.length ? organizationIds : undefined,
                locations: value.length ? value : undefined,
              });
            }}
            searchable
          />
          <Button variant="subtle" color="red" leftSection={<IconX size={16} />} onClick={handleReset}>
            Reset
          </Button>
        </Group>
      )}
    </Group>
  );
}
