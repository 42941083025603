export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;

export const DAY_OF_WEEK_LABELS = {
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday',
  '7': 'Sunday',
};

export const DAY_OF_WEEK_LABELS_SHORT = {
  '1': 'Mon',
  '2': 'Tue',
  '3': 'Wed',
  '4': 'Thu',
  '5': 'Fri',
  '6': 'Sat',
  '7': 'Sun',
};

export const DAY_OF_WEEK_LABELS_ABBREVIATED = {
  '1': 'M',
  '2': 'Tu',
  '3': 'W',
  '4': 'Th',
  '5': 'F',
  '6': 'Sa',
  '7': 'Su',
};

export const SAN_FRANCISCO_MAP_CENTER = { lat: 37.7749, lng: -122.4194 };

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
