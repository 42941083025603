import { Card, Group, Stack, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { IconEdit, IconTrash } from '@tabler/icons-react';

import { TooltipActionIcon } from '@components/TooltipActionIcon';
import { usePreferences } from '@features/preferences';

import { useDeleteLocationOverride } from '../api/deleteLocationOverride';
import { usePointsOfInterest } from '../api/getPointsOfInterest';
import { LocationOverride, LocationType } from '../types';
import { formatLocationAddress } from '../utils/formatLocationAddress';
import { formatEnd, formatStart } from '../utils/formatTime';
import { countryCodeToFlag } from '../utils/getCountryEmoji';

import { LocationTypeBadge } from './LocationTypeBadge';

interface LocationOverrideCardProps {
  override: LocationOverride;
  onEdit: (override: LocationOverride) => void;
}

export const LocationOverrideCard = ({ override, onEdit }: LocationOverrideCardProps) => {
  const { mutate: deleteLocationOverride } = useDeleteLocationOverride();
  const { data: preferences } = usePreferences();
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { data: pointOfInterests } = usePointsOfInterest();
  const homePointOfInterest = pointOfInterests?.find((poi) => poi.locationType === LocationType.HOME);

  if (!preferences) return null;

  const userTimeZone = preferences.timeZone || browserTimeZone;

  const location = override.location || override.pointOfInterest?.location;

  if (!location) {
    // This should not happen location or point of interest should always be present
    Sentry.captureException(new Error('Location not found'));
    return null;
  }

  const overrideTimeZone = location.timeZone || userTimeZone;
  // Shortcut to determine if we should show the country flag
  const isOutsideHomeCountry = location.country !== homePointOfInterest?.location.country;

  return (
    <Card key={override.id} padding="xs" withBorder>
      <Group justify="space-between">
        <Stack gap="2px">
          {isOutsideHomeCountry && (
            <Title order={6}>
              {countryCodeToFlag(location.country)} {location.locality || location.name}, {location.country}
            </Title>
          )}
          <Group gap="xs" maw={400}>
            <Text fw={600} size="xs">
              Location:
            </Text>
            {override.pointOfInterest && (
              <>
                <LocationTypeBadge size="xs" iconSize="12px" locationType={override.pointOfInterest.locationType} />
                <Text size="xs">{override.pointOfInterest.name}</Text>
              </>
            )}
            {override.location && <Text size="xs">{formatLocationAddress(override.location)}</Text>}
          </Group>
          {overrideTimeZone !== userTimeZone && (
            <Group gap="xs">
              <Text fw={600} size="xs">
                Timezone:
              </Text>
              <Text span size="xs">
                {overrideTimeZone}
              </Text>
            </Group>
          )}
          <Group gap="xs">
            <Text fw={600} size="xs">
              Dates:
            </Text>
            {override.timeWindow.end.diff(override.timeWindow.start, 'days').toObject().days === 1 ? (
              <Text size="xs">{formatStart(override.timeWindow.start, userTimeZone)}</Text>
            ) : (
              <>
                <Text size="xs">{formatStart(override.timeWindow.start, userTimeZone)}</Text> -{' '}
                <Text size="xs">{formatEnd(override.timeWindow.end, userTimeZone)}</Text>
              </>
            )}
          </Group>
        </Stack>
        <Stack gap="0">
          <TooltipActionIcon onClick={() => onEdit(override)} variant="subtle" icon={<IconEdit size={16} />} />
          <TooltipActionIcon
            onClick={() => deleteLocationOverride(override.id)}
            variant="subtle"
            icon={<IconTrash size={16} />}
            color="red"
          />
        </Stack>
      </Group>
    </Card>
  );
};
