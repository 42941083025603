import { useCallback, useEffect, useState } from 'react';

import { Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useBlocker } from 'react-router-dom';

// TypeScript interface for hook props
interface UsePromptProps {
  when: boolean;
  message: string;
}

/**
 * usePrompt hook to render modal on navigate to
 * replace react-router-dom's usePrompt since it is
 * currently unsupported
 */
export const usePrompt = ({ when, message }: UsePromptProps) => {
  const [isBlocked, setIsBlocked] = useState<boolean>(when);
  const modals = useModals();

  const showModal = useCallback(() => {
    modals.openConfirmModal({
      title: 'Confirm Navigation',
      children: <Text>{message}</Text>,
      labels: { confirm: 'Yes, leave', cancel: 'No, stay' },
      onConfirm: () => {
        setIsBlocked(false);
        modals.closeAll();
      },
      onCancel: () => {
        // Do nothing, stay on the current page
      },
    });
  }, [message, modals]);

  const blocker = useBlocker(
    useCallback(() => {
      if (when) {
        showModal();
        return true; // Block the navigation
      }
      return false; // Allow the navigation
    }, [when, showModal]),
  );

  useEffect(() => {
    setIsBlocked(when);
  }, [when]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isBlocked) {
      blocker.proceed();
    }
  }, [blocker, isBlocked]);

  return [isBlocked, setIsBlocked] as const;
};
