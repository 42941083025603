import { DayOfWeek } from '@/types';

import { Availability } from '../types';

export const getEmptyAvailability = (): Availability => {
  return {
    [DayOfWeek.Monday]: [],
    [DayOfWeek.Tuesday]: [],
    [DayOfWeek.Wednesday]: [],
    [DayOfWeek.Thursday]: [],
    [DayOfWeek.Friday]: [],
    [DayOfWeek.Saturday]: [],
    [DayOfWeek.Sunday]: [],
  };
};
