import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { axios } from '@lib/axios';

import { Contact, Organization } from '../types';

interface ContactResponse extends Omit<Contact, 'lastMeeting'> {
  lastMeeting?: string;
}

interface GetContactsResponse {
  contacts: ContactResponse[];
  organizations: Organization[];
}

const fetchContacts = async (): Promise<{ contacts: Contact[]; organizations: Organization[] }> => {
  const { data } = await axios.get<GetContactsResponse>('/contacts');
  return {
    contacts: data.contacts.map((contact) => ({
      ...contact,
      lastMeeting: contact.lastMeeting ? DateTime.fromISO(contact.lastMeeting) : undefined,
    })),
    organizations: data.organizations,
  };
};

export const useContactsWithOrganizations = () => {
  return useQuery<{ contacts: Contact[]; organizations: Organization[] }, Error>({
    queryKey: ['contacts'],
    queryFn: () => fetchContacts(),
    retry: false,
  });
};
