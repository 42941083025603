import { useState } from 'react';

import { Group, Text, TextInput } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

import { getDomainWithoutExtension } from '@utils/emailUtil';
import { capitalizeFirstLetter } from '@utils/stringUtil';

import { useUpdateCompanyName } from '../api/updateCompanyName';
import { AccountWithCalendars } from '../types';

interface AccountCompanyNameProps {
  account: AccountWithCalendars;
}

export function AccountCompanyName({ account }: AccountCompanyNameProps) {
  const [companyName, setCompanyName] = useState(account.companyName || '');
  const { mutate: updateCompanyName } = useUpdateCompanyName({
    onSuccess: () =>
      notifications.show({
        title: 'Company name updated',
        message: 'Company name updated successfully',
        color: 'green',
      }),
  });

  const debouncedUpdateCompanyName = useDebouncedCallback((value: string) => {
    updateCompanyName({
      accountId: account.accountId,
      companyName: value ? value.trim() : null,
    });
  }, 500);

  const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setCompanyName(newValue);
    debouncedUpdateCompanyName(newValue);
  };

  return (
    <Group align="center" gap="xs">
      <Text size="sm">Company name for event title:</Text>
      <TextInput
        value={companyName}
        onChange={handleCompanyNameChange}
        placeholder={
          getDomainWithoutExtension(account.email)
            ? `e.g. ${capitalizeFirstLetter(getDomainWithoutExtension(account.email))}`
            : ''
        }
        size="xs"
      />
    </Group>
  );
}
