import { DateTime } from 'luxon';

import { DayOfWeek } from '@/types';

import { Availability, WorkingHourSettings } from '../types';

export const constrainAvailabilityToWorkingHours = (
  availability: Availability,
  workingHours: WorkingHourSettings,
): Availability => {
  const newAvailability: Availability = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
  };
  Object.entries(availability).forEach(([day, dayAvailability]) => {
    const workingHoursForDay = workingHours[day as DayOfWeek];
    if (!workingHoursForDay || workingHoursForDay.length === 0) {
      return;
    }
    newAvailability[day as DayOfWeek] = dayAvailability
      .map((block) => {
        workingHoursForDay;
        const { start, end } = block;
        const startTime = DateTime.fromFormat(start, 'HH:mm');
        const endTime = DateTime.fromFormat(end, 'HH:mm');
        const isWithinWorkingHours = workingHoursForDay.some((workingHourSlot) => {
          const [workingStart, workingEnd] = workingHourSlot;
          const workingStartTime = DateTime.fromFormat(workingStart, 'HH:mm');
          const workingEndTime = DateTime.fromFormat(workingEnd, 'HH:mm');

          if (startTime >= workingStartTime && endTime <= workingEndTime) {
            return true;
          }
          return false;
        });

        if (!isWithinWorkingHours) {
          return null;
        }

        return {
          start: startTime.toFormat('HH:mm'),
          end: endTime.toFormat('HH:mm'),
          type: block.type,
        };
      })
      .filter((block) => block !== null) as Availability[DayOfWeek];
  });
  return newAvailability;
};
