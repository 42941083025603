import { Anchor, Select, Stack, Text, Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Link } from 'react-router-dom';

import { useZoomAccounts } from '@features/integrations';
import { PRIVATE_ROUTES } from '@routes/enums';

interface ZoomAccountSelectorProps {
  initialZoomAccountId: string | null;
  isDirty: boolean;
  onZoomAccountChange: (zoomAccountId: string) => void;
}

export const ZoomAccountSelector = ({
  initialZoomAccountId,
  isDirty,
  onZoomAccountChange,
}: ZoomAccountSelectorProps) => {
  const { data: zoomAccounts } = useZoomAccounts();

  const form = useForm({
    initialValues: {
      zoomAccountId: initialZoomAccountId || '',
    },
  });

  const handleSubmit = (values: { zoomAccountId: string }) => {
    onZoomAccountChange(values.zoomAccountId);
    form.reset();
  };

  if (!zoomAccounts) {
    return null;
  }

  if (zoomAccounts.length === 0) {
    return (
      <Stack gap="xs" align="flex-start">
        <Text fw={500} size="sm" c="red">
          No Zoom accounts found
        </Text>
        <Text size="sm">
          Please{' '}
          <Anchor component={Link} to={PRIVATE_ROUTES.INTEGRATIONS} fw={500}>
            add a Zoom integration
          </Anchor>{' '}
          to use Zoom for virtual meetings.
        </Text>
      </Stack>
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Select
          label="Choose Zoom Account"
          placeholder="Select a Zoom account"
          data={zoomAccounts.map((account) => ({ value: account.id, label: account.email }))}
          {...form.getInputProps('zoomAccountId')}
          miw={360}
        />
        {(form.isDirty() || isDirty) && <Button type="submit">Save</Button>}
      </Stack>
    </form>
  );
};
