import { useEffect } from 'react';

import { AnalyticsEventName } from '../types';
import { track } from '../utils/track';

export const useLogPageView = (pageName?: string) => {
  useEffect(() => {
    if (!pageName) return;

    track({
      type: AnalyticsEventName.PageView,
      data: { pageName },
    });
  }, [pageName]);
};
