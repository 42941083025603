import { FC, useState } from 'react';

import { LoadingOverlay, Modal, Button, Stack, Title, Divider, Text, Tooltip } from '@mantine/core';
import { IconCirclePlus, IconRefresh } from '@tabler/icons-react';
import { DateTime } from 'luxon';

import { useLocationOverrides } from '../api/getLocationOverrides';
import { useSyncLocationOverrides } from '../api/syncLocationOverrides';
import { useUpsertLocationOverride } from '../api/upsertLocationOverride';
import { LocationOverride, UpsertLocationOverrideParams } from '../types';

import { LocationOverrideCard } from './LocationOverrideCard';
import { LocationOverrideForm } from './LocationOverrideForm';

export const LocationOverridesList: FC = () => {
  const { data: locationOverrides, isLoading } = useLocationOverrides({
    start: DateTime.now().minus({ days: 1 }),
    end: DateTime.now().plus({ months: 6 }),
  });
  const { mutate: syncLocationOverrides, isPending: isSyncLocationOverridesLoading } = useSyncLocationOverrides();
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedLocationOverride, setSelectedLocationOverride] = useState<LocationOverride | undefined>(undefined);

  const handleAddOrEditLocationOverride = (override?: LocationOverride) => {
    setSelectedLocationOverride(override);
    setModalOpened(true);
  };

  const handleModalClose = () => {
    setModalOpened(false);
    setSelectedLocationOverride(undefined);
    clearUpsertError();
  };
  const {
    mutate: upsertLocationOverride,
    error: upsertError,
    reset: clearUpsertError,
  } = useUpsertLocationOverride({
    onSuccess: () => {
      setModalOpened(false);
    },
  });

  if (isLoading) {
    return <LoadingOverlay visible={isLoading} />;
  }

  if (!locationOverrides) {
    return null;
  }

  const handleSubmit = (values: UpsertLocationOverrideParams) => {
    upsertLocationOverride(values);
  };

  return (
    <Stack gap="xs">
      <Title order={6} mt="4">
        Overrides
      </Title>
      <Divider />
      <Modal
        opened={modalOpened}
        onClose={handleModalClose}
        title={selectedLocationOverride ? 'Edit Location Override' : 'Add Location Override'}
        size="xs"
      >
        <LocationOverrideForm
          onSubmit={handleSubmit}
          errorMessage={upsertError?.response?.data.message}
          initialLocationOverride={selectedLocationOverride}
        />
      </Modal>
      {locationOverrides.length === 0 && (
        <Text c="dimmed" size="sm" ta="center">
          No upcoming location overrides
        </Text>
      )}
      {locationOverrides
        .sort((a, b) => a.timeWindow.start.diff(b.timeWindow.start).milliseconds)
        .map((override) => (
          <LocationOverrideCard key={override.id} override={override} onEdit={handleAddOrEditLocationOverride} />
        ))}
      <Stack>
        <Button
          variant="outline"
          leftSection={<IconCirclePlus size={16} />}
          onClick={() => handleAddOrEditLocationOverride()}
        >
          Add manual override
        </Button>
        <Tooltip
          withArrow
          w="220"
          multiline
          position="bottom"
          // eslint-disable-next-line max-len
          label='We will look for all-day events starting with "trip" or flight events including the word "flight" to create location overrides.'
        >
          <Button
            variant="outline"
            leftSection={<IconRefresh size={16} />}
            onClick={() => syncLocationOverrides()}
            loading={isSyncLocationOverridesLoading}
          >
            Sync from calendar
          </Button>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
