import { useState } from 'react';

import { Box, Button, Center, Divider, Grid, Modal, Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { DayOfWeek, GooglePlaceInfo } from '@/types';
import { SideContent } from '@components/SideContent';

import { usePointsOfInterest } from '../api/getPointsOfInterest';
import { useUpsertPointOfInterest } from '../api/upsertPointOfInterest';
import { LocationType, PointOfInterest } from '../types';

import { LocationOverridesList } from './LocationOverridesList';
import { PointOfInterestForm } from './PointOfInterestForm';
import { PointOfInterestMap } from './PointOfInterestMap';
import { PointOfInterestTable } from './PointOfInterestTable';

export const PlacesPage = () => {
  const { data: pointsOfInterest } = usePointsOfInterest();
  const { mutate: upsertPointOfInterest } = useUpsertPointOfInterest();

  const [currentPointOfInterestToEdit, setCurrentPointOfInterestToEdit] = useState<PointOfInterest | null>(null);

  const [isPointOfInterestFormOpen, setIsPointOfInterestFormOpen] = useState(false);

  const handleAddOrEditPointOfInterest = (pointOfInterest?: PointOfInterest) => {
    setIsPointOfInterestFormOpen(true);
    if (pointOfInterest) {
      setCurrentPointOfInterestToEdit(pointOfInterest);
    }
  };

  const handleCloseDialog = () => {
    setCurrentPointOfInterestToEdit(null);
    setIsPointOfInterestFormOpen(false);
  };

  const handleSubmit = (values: {
    location: GooglePlaceInfo | null;
    nickname: string;
    locationType: LocationType;
    anchorAvailability: DayOfWeek[];
    instructions: string;
  }) => {
    const { location, nickname, ...otherValues } = values;
    upsertPointOfInterest({
      ...otherValues,
      name: nickname,
      googlePlaceId: location?.placeId || '',
      locationDescription: location?.description || '',
      id: currentPointOfInterestToEdit?.id,
    });

    handleCloseDialog();
  };

  if (!pointsOfInterest) return null;

  return (
    <SideContent
      sideContent={
        <Stack>
          <Text c="dimmed">Add your locations to ensure that we suggest places to meet near you.</Text>
          <Button leftSection={<IconPlus size={16} />} onClick={() => handleAddOrEditPointOfInterest()}>
            Add location
          </Button>
        </Stack>
      }
    >
      <Stack>
        <PointOfInterestMap existingPointsOfInterest={pointsOfInterest} />
        <Box w="100%">
          <Modal
            styles={{
              inner: {
                left: 0, // Hack to get the modal to render in the correct location with Portal disabled
              },
            }}
            w="100%"
            opened={isPointOfInterestFormOpen}
            onClose={handleCloseDialog}
            title={currentPointOfInterestToEdit ? 'Edit Location' : 'Add Location'}
            withinPortal={false} // Necessary to prevent a weird shadow under the modal when it changes size
          >
            <PointOfInterestForm onSubmit={handleSubmit} initialPointOfInterest={currentPointOfInterestToEdit} />
          </Modal>
          <Grid h="100%" w="100%" overflow="hidden">
            <Grid.Col span={{ base: 12, lg: 8 }} h="100%">
              <PointOfInterestTable
                pointsOfInterest={pointsOfInterest}
                onAddOrEditPointOfInterest={handleAddOrEditPointOfInterest}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 0.5 }}>
              <Center h="100%">
                <Divider orientation="vertical" />
              </Center>
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 3.5 }}>
              <LocationOverridesList />
            </Grid.Col>
          </Grid>
        </Box>
      </Stack>
    </SideContent>
  );
};
