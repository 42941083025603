import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import type { User } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';

import { setAnalyticsUserId } from '@features/analytics';

import { firebaseAuth } from '../config/firebaseAuth';

interface AuthState {
  loading: boolean;
  signedIn: boolean;
  uid: string | null;
  user: User | null;
}

export const useFirebaseAuthState = () => {
  const [authState, setFirebaseAuthState] = useState<AuthState>({
    loading: true,
    signedIn: false,
    uid: null,
    user: null,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (authUser) => {
      if (authUser) {
        // User is signed in.
        setFirebaseAuthState({
          loading: false,
          signedIn: true,
          uid: authUser.uid,
          user: authUser,
        });
        Sentry.setUser({
          id: authUser.uid,
          phoneNumber: authUser.phoneNumber,
        });
        setAnalyticsUserId(authUser.uid);
      } else {
        // User is signed out.
        setFirebaseAuthState({
          loading: false,
          signedIn: false,
          uid: null,
          user: null,
        });
        Sentry.setUser(null);
        setAnalyticsUserId(null);
      }
    });
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return authState;
};
