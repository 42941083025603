import React from 'react';

import { Group, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';

import { TimeInputSelect } from '@/components/Form/TimeInputSelect';
import { DayOfWeek, TimeOfDay } from '@/types';
import { TooltipActionIcon } from '@components/TooltipActionIcon';

interface WorkingHourTimeInputProps {
  day: DayOfWeek;
  index: number;
  start: TimeOfDay;
  end: TimeOfDay;
  onWorkingHourChange: (day: DayOfWeek, index: number, start: string, end: string) => void;
  onRemoveWorkingHours: (day: DayOfWeek, index: number) => void;
}

export const WorkingHourTimeInput: React.FC<WorkingHourTimeInputProps> = ({
  day,
  index,
  start,
  end,
  onWorkingHourChange,
  onRemoveWorkingHours,
}) => {
  return (
    <Group mb="xs" justify="space-between">
      <Group gap="xs">
        <TimeInputSelect
          size="sm"
          w={110}
          value={start}
          onChange={(newValue) => onWorkingHourChange(day, index, newValue, end)}
        />
        <Text>-</Text>
        <TimeInputSelect
          size="sm"
          w={110}
          value={end}
          onChange={(newValue) => onWorkingHourChange(day, index, start, newValue)}
        />
      </Group>
      <TooltipActionIcon
        onClick={() => onRemoveWorkingHours(day, index)}
        size="xs"
        icon={<IconX size={16} />}
        title="Remove working hours"
      />
    </Group>
  );
};
