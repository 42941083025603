import { useQuery } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { ZoomAccountDetails } from '../types';

const getZoomAccounts = async (): Promise<ZoomAccountDetails[]> => {
  const response = await axios.get('/zoom/accounts');
  return response.data;
};

export const useZoomAccounts = () => {
  return useQuery({
    queryKey: ['zoomAccounts'],
    queryFn: getZoomAccounts,
  });
};
