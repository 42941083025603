import React from 'react';

import { Text, Button, Container, Title, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Title order={1}>404 - Page Not Found</Title>
      <Text size="lg" mt="md">
        The page you are looking for doesn&apos;t exist or has been moved.
      </Text>
      <Group mt="xl">
        <Button onClick={() => navigate('/')}>Go back to home</Button>
      </Group>
    </Container>
  );
};
