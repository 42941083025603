import React from 'react';

import { Badge, Card, Group, Stack, Text } from '@mantine/core';
import { IconMessages } from '@tabler/icons-react';

import { TooltipIcon } from '@components/TooltipIcon';

import { MeetingForDisplay } from '../types';

import { AttendeeList } from './AttendeeList';

interface MeetingItemProps {
  meeting: MeetingForDisplay;
}

export const MeetingItem: React.FC<MeetingItemProps> = ({ meeting }) => {
  const { startTime } = meeting;
  return (
    <Card padding="md" radius="md" withBorder>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap="sm">
          <Group gap="xs">
            <Text fw={500} size="sm">
              {meeting.title || 'Untitled Meeting'}
            </Text>
            {meeting.codewordTemplateName && (
              <Badge size="sm" variant="light" color="blue">
                {meeting.codewordTemplateName}
              </Badge>
            )}
            {meeting.location && (
              <Badge size="sm" variant="filled" color="green">
                In-Person
              </Badge>
            )}
          </Group>
          <Group gap="xs">
            <Text size="sm" c="dimmed">
              {startTime.toFormat('EEE, MMM d • h:mm a')}
            </Text>
            {meeting.location && (
              <Text size="sm" c="dimmed">
                • {meeting.location}
              </Text>
            )}
          </Group>
          {meeting.threadSummary && (
            <Group gap="xs" wrap="nowrap">
              <TooltipIcon title="Meeting Summary" icon={<IconMessages size={16} />} />
              <Text size="sm" style={{ wordBreak: 'break-word' }}>
                {meeting.threadSummary}
              </Text>
            </Group>
          )}
          <AttendeeList attendees={meeting.attendees} />
        </Stack>
      </Group>
    </Card>
  );
};
