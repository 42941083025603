import { useMutation } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { CalendarStats } from '../utils/calendarStatsUtil';

interface RelayApiPayload {
  calendarStats: CalendarStats;
  email: string;
  firstName: string;
  lastName: string;
  isQualified: boolean;
}

const joinWaitlistRequest = async (payload: RelayApiPayload): Promise<void> => {
  await axios.post('/waitlist/join', payload);
};

export const useJoinWaitlist = (onSuccess: () => void, onError: () => void) => {
  return useMutation({
    mutationFn: joinWaitlistRequest,
    onSuccess,
    onError,
  });
};
