import { FC } from 'react';

import { Box, Card, Group, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { IconChevronRight, IconProps } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import classes from './NavLink.module.css';
interface NavLinkProps {
  label: string;
  description: string;
  Icon: React.ComponentType<IconProps>;
  relativePath: string;
  isActive: boolean;
}

export const NavLink: FC<NavLinkProps> = ({ label, description, Icon, relativePath, isActive }) => {
  const color = isActive ? 'blue' : 'black';
  return (
    <Card
      component={Link}
      to={relativePath}
      className={`${classes.navLink} ${isActive ? classes.navLinkActive : classes.navLinkInactive}`}
      color="blue"
      p="s"
    >
      <Group justify="space-between">
        <Group align="flex-start">
          <Box pt="2">
            <ThemeIcon color={color} variant="subtle">
              <Icon size={20} />
            </ThemeIcon>
          </Box>
          <Stack gap="xs">
            <Title order={5} component={'h2'} c={color}>
              {label}
            </Title>
            <Text size="xs">{description}</Text>
          </Stack>
        </Group>
        <ThemeIcon color={color} variant="subtle">
          <IconChevronRight size={16} />
        </ThemeIcon>
      </Group>
    </Card>
  );
};
