import { useCallback, useEffect, useRef } from 'react';

import { Loader } from '@mantine/core';
import axios, { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { PageContainer } from '@components/PageContainer';
import { useLogPageView } from '@features/analytics';
import { PUBLIC_ROUTES } from '@routes/enums';

const GOOGLE_WEB_CLIENT_ID = import.meta.env.VITE_GOOGLE_WEB_CLIENT_ID || '';
const GOOGLE_WEB_CLIENT_SECRET = import.meta.env.VITE_GOOGLE_WEB_CLIENT_SECRET || '';
/**
 * The Google OAuth popup redirects to this page after the user authorizes
 * a calendar.
 */
export const TempGoogleOAuthPage = () => {
  const [searchParams] = useSearchParams();
  useLogPageView('Google OAuth for Waitlist');

  const hasCheckedAuthorization = useRef(false);

  const checkAuthorization = useCallback(async () => {
    try {
      const code = searchParams.get('code');

      if (!code) {
        throw new Error('missing code');
      }
      const response = await axios.post(
        'https://oauth2.googleapis.com/token',
        new URLSearchParams({
          code,
          client_id: GOOGLE_WEB_CLIENT_ID,
          client_secret: GOOGLE_WEB_CLIENT_SECRET,
          redirect_uri: `${window.location.origin}${PUBLIC_ROUTES.GOOGLE_OAUTH_READONLY}`,
          grant_type: 'authorization_code',
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch access token');
      }

      const data = response.data;

      const accessToken = data.access_token;
      const idToken = data.id_token;

      if (!accessToken || !idToken) {
        throw new Error('Access token not found in response');
      }

      // Send a message that the account was added so that we know when this page closes
      window.opener.postMessage({ type: 'oauth-success', accessToken, idToken }, window.location.origin);
    } catch (err) {
      if (err instanceof AxiosError && err.response?.data) {
        window.opener.postMessage({ type: 'oauth-error', message: err.response.data.message }, window.location.origin);
      } else {
        window.opener.postMessage(
          { type: 'oauth-error', message: 'Unable to add account. Please try again.' },
          window.location.origin,
        );
      }
    }
    window.close();
  }, [searchParams]);

  useEffect(() => {
    if (!hasCheckedAuthorization.current) {
      hasCheckedAuthorization.current = true;
      checkAuthorization();
    }
  }, [checkAuthorization]);

  return (
    <PageContainer>
      <Loader size={30} />
    </PageContainer>
  );
};
