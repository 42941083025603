import { DateTime } from 'luxon';

import { DayOfWeek, GooglePlaceInfo, TimeWindow } from '@/types';

export interface PlaceLocation {
  id: string;
  name: string;
  administrativeAreaLevel1: string; // State / Province / Region (ISO code when available)
  administrativeAreaLevel2: string; // County / District
  locality: string; // city / town
  sublocality: string; // Neighborhoods or boroughs
  postalCode: string;
  streetNumber: string;
  route: string; // street name
  subpremise: string; // Apartment, Suite, Box number, etc.
  country: string;
  neighborhood: string;
  vicinity: string;
  googlePlaceId: string;
  description: string;
  latitude: number;
  longitude: number;
  timeZone: string;
  creatorId: string;
}

export interface PointOfInterest {
  id: string;
  name: string;
  location: PlaceLocation;
  locationType: LocationType;
  userId: string; // Foreign key to User
  isAnchor: boolean;
  anchorAvailability: DayOfWeek[];
  instructions: string;
}

export interface AnchorAvailability {
  id: string;
  pointOfInterest: PointOfInterest;
  dayOfWeek: DayOfWeek; // Day of week
  userId: string;
}

export enum LocationType {
  COFFEE = 'coffee',
  OFFICE = 'office',
  HOME = 'home',
}

export interface LocationResponse extends Omit<PlaceLocation, 'latitude' | 'longitude'> {
  latitude: string;
  longitude: string;
}

export interface PointOfInterestResponse extends Omit<PointOfInterest, 'location'> {
  location: LocationResponse;
}

export interface LocationOverride {
  id: string;
  location: PlaceLocation | null;
  pointOfInterest: PointOfInterest | null;
  userId: string;
  timeWindow: {
    start: DateTime;
    end: DateTime;
  };
}

export interface LocationOverrideResponse {
  id: string;
  userId: string;
  location: LocationResponse | null;
  pointOfInterest: PointOfInterestResponse | null;
  timeWindow: {
    start: string;
    end: string;
  };
}

export interface UpsertLocationOverrideParams {
  id?: string;
  location: GooglePlaceInfo | null;
  pointOfInterestId: string | null;
  timeWindow: TimeWindow;
}
