import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { WorkingHourSettings } from '../types';

export const getWorkingHours: () => Promise<WorkingHourSettings> = async () => {
  const { data } = await axios.get('/user/workingHours');
  return data.workingHours;
};

export const useWorkingHours = (options?: Omit<UseQueryOptions<WorkingHourSettings>, 'queryFn' | 'queryKey'>) =>
  useQuery({ queryKey: ['workingHours'], queryFn: getWorkingHours, ...options });
