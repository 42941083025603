import React from 'react';

import { Group, Title, TitleProps } from '@mantine/core';

interface TitleWithIconProps extends Omit<TitleProps, 'children'> {
  title: string;
  icon: React.ReactNode;
}

export const TitleWithIcon: React.FC<TitleWithIconProps> = ({ title, icon, ...titleProps }) => (
  <Group gap="xs">
    {icon}
    <Title {...titleProps}>{title}</Title>
  </Group>
);
