import { FC, useRef, useState } from 'react';

import { Button, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FirebaseError } from 'firebase/app';
import type { ConfirmationResult } from 'firebase/auth';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { E164Number, isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

import { PhoneNumberInput } from '@components/Form/PhoneNumberInput';

import { firebaseAuth } from '../config/firebaseAuth';
import { formatFirebaseError } from '../utils/formatFirebaseError';

interface FormValues {
  phoneNumber: E164Number | undefined;
}

interface PhoneInputFormProps {
  confirmationRef: React.MutableRefObject<undefined | ConfirmationResult>;
  onPhoneInputSuccess: () => void;
}

export const PhoneInputForm: FC<PhoneInputFormProps> = (props) => {
  const { confirmationRef, onPhoneInputSuccess } = props;

  const recaptchaWrapperRef = useRef<HTMLDivElement | null>(null);

  const [isCaptchaOpen, setIsCaptchaOpen] = useState(false);
  const initialValues: { phoneNumber: E164Number | undefined } = {
    phoneNumber: undefined,
  };
  const form = useForm({
    initialValues,
    validate: {
      phoneNumber: (value) => {
        return isValidPhoneNumber(value || '') ? null : 'Invalid phone number';
      },
    },
  });

  const onSubmit = async (values: FormValues) => {
    setIsCaptchaOpen(true);
    const recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, 'recaptcha-container', {
      size: 'invisible',
    });
    try {
      confirmationRef.current = await signInWithPhoneNumber(
        firebaseAuth,
        parsePhoneNumberFromString(values.phoneNumber as string, 'US')?.formatInternational() || '',
        recaptchaVerifier,
      );
    } catch (error) {
      if (error instanceof FirebaseError) {
        form.setErrors({ phoneNumber: formatFirebaseError(error.code) });
      } else {
        form.setErrors({ phoneNumber: 'An error occurred' });
      }
      return;
    } finally {
      recaptchaVerifier.clear();
      if (recaptchaWrapperRef.current) {
        // In order to clear the recaptcha, we need to create a new div
        recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
      }
      setIsCaptchaOpen(false);
    }
    onPhoneInputSuccess();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <>
          <PhoneNumberInput {...form.getInputProps('phoneNumber')} />
          <Button loading={isCaptchaOpen} type="submit" radius="lg" fullWidth>
            Get code
          </Button>
        </>
        <div ref={(ref) => (recaptchaWrapperRef.current = ref)}>
          <div id="recaptcha-container"></div>
        </div>
      </Stack>
    </form>
  );
};
