import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { AnalyticsEventName, track } from '@features/analytics';
import { axios, RequestError } from '@lib/axios';
import { convertToTimeWindowResponse, transformTimeWindowResponse } from '@utils/transformTimeWindowResponse';

import { LocationOverride, UpsertLocationOverrideParams } from '../types';
import { transformLocationResponse } from '../utils/transformLocationResponse';
import { transformPointOfInterestResponse } from '../utils/transformPointOfInterestResponse';

const upsertLocationOverride = async ({
  id,
  location,
  pointOfInterestId,
  timeWindow,
}: UpsertLocationOverrideParams): Promise<LocationOverride> => {
  const { data } = await axios.post('/location/location-overrides', {
    id,
    location,
    pointOfInterestId,
    timeWindow: convertToTimeWindowResponse(timeWindow),
  });

  return {
    id: data.id,
    userId: data.userId,
    location: data.location ? transformLocationResponse(data.location) : null,
    pointOfInterest: data.pointOfInterest ? transformPointOfInterestResponse(data.pointOfInterest) : null,
    timeWindow: transformTimeWindowResponse(data.timeWindow),
  };
};

export const useUpsertLocationOverride = (
  options?: Omit<UseMutationOptions<LocationOverride, unknown, UpsertLocationOverrideParams>, 'mutationFn'>,
) => {
  const queryClient = useQueryClient();

  return useMutation<LocationOverride, RequestError, UpsertLocationOverrideParams>({
    ...options,
    mutationFn: upsertLocationOverride,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.refetchQueries({
        queryKey: ['locationOverrides'],
      });
      track({
        type: AnalyticsEventName.LocationOverrideManuallyAddedOrEdited,
        data: { locationOverrideId: data.id },
      });
    },
  });
};
