import { Avatar, Group, Text } from '@mantine/core';

import { Organization } from '../types';

interface OrganizationDisplayProps {
  organization?: Organization;
  isPast?: boolean;
}

export const OrganizationDisplay = ({ organization, isPast = false }: OrganizationDisplayProps) => {
  if (!organization) {
    return null;
  }

  return (
    <Group gap="sm" wrap="nowrap">
      <Avatar src={organization.logoUrl} alt={organization.name} size="sm" radius="sm">
        {organization.name ? organization.name[0] : ''}
      </Avatar>
      <Text size="sm" c={isPast ? 'dimmed' : undefined}>
        {organization.name} {isPast && '(past)'}
      </Text>
    </Group>
  );
};
