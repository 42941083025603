import { useQuery } from '@tanstack/react-query';

import { useFirebaseAuthState } from '@features/auth';
import { axios } from '@lib/axios';

import { AccountWithCalendars } from '../types';

const getAllCalendarsByAccounts = async (userId: string): Promise<AccountWithCalendars[]> => {
  const { data } = await axios.get(`/account/${userId}/listWithCalendars`);
  return data.accounts;
};

const getUserCalendars = async (userId?: string) => {
  if (userId) {
    return await getAllCalendarsByAccounts(userId);
  } else {
    return [];
  }
};

export const useCalendarsByAccounts = () => {
  const { user } = useFirebaseAuthState();
  return useQuery({
    queryKey: ['calendarsByAccounts', user?.uid],
    queryFn: () => getUserCalendars(user?.uid),
    enabled: !!user,
  });
};
