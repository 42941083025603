import { Button, Group, Select, Stack, TagsInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { LocationAutocomplete } from '@components/Form/LocationAutocomplete';
import { EMAIL_REGEX } from '@constants/index';

import { useUpsertContact } from '../api/upsertContact';
import { Contact, CreateContactDTO, Organization } from '../types';

interface ContactFormProps {
  initialData?: Contact;
  organizations: Organization[];
  onSubmit?: () => void;
  onCancel?: () => void;
  isCreate?: boolean;
}

export function ContactForm({ initialData, organizations, onSubmit, onCancel, isCreate }: ContactFormProps) {
  const { mutate: upsertContact, isPending: isUpserting } = useUpsertContact();

  const form = useForm<CreateContactDTO>({
    initialValues: {
      firstName: initialData?.firstName || '',
      lastName: initialData?.lastName || '',
      emails: initialData?.emails || [],
      currentOrganizationId: initialData?.currentOrganizationId || '',
      linkedInUrl: initialData?.linkedInUrl || '',
      currentTitle: initialData?.currentTitle || '',
      location: null,
    },
    validate: {
      firstName: (value) => (!value ? 'First name is required' : null),
      lastName: (value) => (!value ? 'Last name is required' : null),
      emails: (value) => {
        if (!value || value.length === 0) return null;
        const invalidEmails = value.filter((email) => !EMAIL_REGEX.test(email));
        return invalidEmails.length > 0 ? 'Invalid email addresses' : null;
      },
      linkedInUrl: (value) => {
        if (!value) return null;
        try {
          new URL(value);
          if (!value.includes('linkedin.com')) {
            return 'Must be a LinkedIn URL';
          }
          return null;
        } catch {
          return 'Invalid URL';
        }
      },
    },
  });

  const handleSubmit = (values: CreateContactDTO) => {
    const onSuccess = () => {
      onSubmit?.();
      form.reset();
    };
    upsertContact({ ...values, id: initialData?.id }, { onSuccess });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack gap="md">
        <Group grow>
          <TextInput label="First Name" placeholder="Enter first name" required {...form.getInputProps('firstName')} />
          <TextInput label="Last Name" placeholder="Enter last name" required {...form.getInputProps('lastName')} />
        </Group>

        <TagsInput
          label="Email Addresses"
          placeholder="Type email and press enter"
          {...form.getInputProps('emails')}
          splitChars={[' ', ',']}
          maxTags={5}
        />

        <Select
          label="Organization"
          placeholder="Select organization"
          data={organizations.map((org) => ({
            value: org.id,
            label: org.name,
          }))}
          {...form.getInputProps('currentOrganizationId')}
          clearable
          searchable
        />
        <TextInput label="Title" placeholder="Enter current title" {...form.getInputProps('currentTitle')} />
        <TextInput
          label="LinkedIn URL"
          placeholder="Enter LinkedIn profile URL"
          {...form.getInputProps('linkedInUrl')}
        />
        <LocationAutocomplete
          initialValue={form.values.location?.description || ''}
          setLocation={(location) => form.setFieldValue('location', location)}
          {...form.getInputProps('location')}
          label="Location"
        />
        <Group justify="flex-end" mt="md">
          {onCancel && (
            <Button variant="light" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button type="submit" loading={isUpserting}>
            {isCreate ? 'Create Contact' : 'Update Contact'}
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
