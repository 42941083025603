import React from 'react';

import { Group, Tabs, Text } from '@mantine/core';
import { DateTime } from 'luxon';

import { CountBadge } from '../../../components/CountBadge';
import { useInSchedulingMeetings } from '../api/getInSchedulingMeetings';
import { useMeetings } from '../api/getMeetings';

import { GroupedMeetings } from './GroupedMeetings';
import { InSchedulingList } from './InSchedulingList';
import styles from './UpcomingMeetings.module.css';

interface UpcomingMeetingsProps {
  start: DateTime;
  end: DateTime;
}

export const UpcomingMeetings: React.FC<UpcomingMeetingsProps> = ({ start, end }) => {
  const { data: inSchedulingMeetings } = useInSchedulingMeetings();
  const { data: confirmedMeetings } = useMeetings({
    start: start.toISO() as string,
    end: end.toISO() as string,
  });

  const inSchedulingCount = inSchedulingMeetings?.length || 0;

  if (!inSchedulingMeetings || !confirmedMeetings) {
    return null;
  }

  return (
    <Tabs defaultValue="inScheduling">
      <Tabs.List
        className={styles.tabsList}
        style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'var(--mantine-color-body)' }}
      >
        <Tabs.Tab value="inScheduling" size="xl">
          <Group gap="xs" wrap="nowrap">
            <CountBadge count={inSchedulingCount} size="lg" color="yellow" />
            <Text>Being Scheduled</Text>
          </Group>
        </Tabs.Tab>
        <Tabs.Tab value="confirmed" size="xl">
          <Group gap="xs" wrap="nowrap">
            <CountBadge count={confirmedMeetings.totalCount} size="lg" color="blue" />
            <Text>Confirmed</Text>
          </Group>
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="inScheduling">
        <InSchedulingList />
      </Tabs.Panel>

      <Tabs.Panel value="confirmed">
        <GroupedMeetings start={start} end={end} order="ASC" />
      </Tabs.Panel>
    </Tabs>
  );
};
