import { ActionIcon } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';

interface ActionsContentProps {
  onEdit?: () => void;
}

export const ActionsContent = ({ onEdit }: ActionsContentProps) => (
  <ActionIcon variant="subtle" onClick={onEdit} title="Edit contact">
    <IconEdit size={16} />
  </ActionIcon>
);
