import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { AnalyticsEventName } from '@/features/analytics/types';
import { track } from '@features/analytics';
import { axios, RequestError } from '@lib/axios';

import { CodewordTemplate } from '../types';

import { API_BASE_URL, QUERY_KEY } from './constants';

interface CreateCodewordTemplateParams {
  name: string;
  description: string;
  keywords: string[];
}

const createCodewordTemplate = async (newTemplate: CreateCodewordTemplateParams): Promise<CodewordTemplate> => {
  const { data } = await axios.post(API_BASE_URL, newTemplate);
  return data;
};

export const useCreateCodewordTemplate = (
  options?: UseMutationOptions<CodewordTemplate, RequestError, CreateCodewordTemplateParams>,
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options || {};
  return useMutation<CodewordTemplate, RequestError, CreateCodewordTemplateParams>({
    mutationFn: createCodewordTemplate,
    onSuccess: (success, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

      // Track the creation event
      track({
        type: AnalyticsEventName.CodewordTemplateCreated,
        data: { templateId: success.id, name: success.name },
      });

      onSuccess?.(success, variables, context);
    },
    ...restOptions,
  });
};
