import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { axios } from '@lib/axios';

import { Attendee, InSchedulingMeeting } from '../types';

interface InSchedulingMeetingResponse {
  id: string;
  threadSummary: string | null;
  codewordTemplateName: string | null;
  attendees: Attendee[];
  emailSubject: string;
  createdAt: string;
  updatedAt: string;
  numberOfDaysUntilNudge: number | null;
  isWaitingForSidechainResponse: boolean;
}

const getInSchedulingMeetings = async (): Promise<InSchedulingMeeting[]> => {
  const { data } = await axios.get<InSchedulingMeetingResponse[]>('/digests/in-scheduling-meetings');
  return data.map((meeting) => ({
    ...meeting,
    createdAt: DateTime.fromISO(meeting.createdAt),
    updatedAt: DateTime.fromISO(meeting.updatedAt),
  }));
};

export const useInSchedulingMeetings = () => {
  return useQuery({
    queryKey: ['in-scheduling-meetings'],
    queryFn: () => getInSchedulingMeetings(),
  });
};
