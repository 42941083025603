import { Text } from '@mantine/core';

import { Contact } from '../../types';

interface TitleContentProps {
  contact: Contact;
}

export const TitleContent = ({ contact }: TitleContentProps) => (
  <Text truncate="end">{contact.currentTitle || '-'}</Text>
);
