import { FC } from 'react';

import { Center, Image, Stack, Text } from '@mantine/core';

import EmptyBoard from '../assets/empty-board.png';
import { CodewordTemplate } from '../types';

import { CodewordTemplateCard } from './CodewordTemplateCard';

interface CodewordTemplateListProps {
  codewordTemplates: CodewordTemplate[];
}

export const CodewordTemplateList: FC<CodewordTemplateListProps> = ({ codewordTemplates }) => {
  return (
    <Stack h="100%">
      {codewordTemplates.length === 0 ? (
        <Center h="100%">
          <Stack align="center">
            <Image src={EmptyBoard} alt="Empty board" />
            <Text w={300} c="dimmed" ta="center">
              Automate your scheduling by adding codeword templates to trigger predefined settings for specific meeting
              types
            </Text>
          </Stack>
        </Center>
      ) : (
        codewordTemplates.map((codewordTemplate) => (
          <CodewordTemplateCard key={codewordTemplate.id} codewordTemplate={codewordTemplate} />
        ))
      )}
    </Stack>
  );
};
