import { PointOfInterest, PointOfInterestResponse } from '../types';

import { transformLocationResponse } from './transformLocationResponse';

export const transformPointOfInterestResponse = (pointOfInterest: PointOfInterestResponse): PointOfInterest => {
  return {
    ...pointOfInterest,
    location: transformLocationResponse(pointOfInterest.location),
  };
};
