import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';

import { useFirebaseAuthState } from '@features/auth';
import { axios } from '@lib/axios';

interface UpdateCompanyNameParams {
  accountId: string;
  companyName: string | null;
}

export const updateCompanyName = async (params: UpdateCompanyNameParams) => {
  const { accountId, companyName } = params;
  await axios.patch(`/account/${accountId}/company-name`, {
    companyName,
  });
  return true;
};

export const useUpdateCompanyName = (options?: UseMutationOptions<boolean, unknown, UpdateCompanyNameParams>) => {
  const queryClient = useQueryClient();
  const { user } = useFirebaseAuthState();
  return useMutation({
    mutationFn: updateCompanyName,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['calendarsByAccounts', user?.uid] });
      options?.onSuccess?.(data, variables, context);
    },
    ...options,
  });
};
