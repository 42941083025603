import { useQuery } from '@tanstack/react-query';

import { axios, RequestError } from '@lib/axios';

import { CodewordTemplate } from '../types';

import { API_BASE_URL, QUERY_KEY } from './constants';

const getCodewordTemplates = async (): Promise<CodewordTemplate[]> => {
  const { data } = await axios.get(API_BASE_URL);
  return data;
};

export const useCodewordTemplates = () =>
  useQuery<CodewordTemplate[], RequestError>({
    queryKey: [QUERY_KEY],
    queryFn: getCodewordTemplates,
    retry: false,
  });
