import { useParams } from 'react-router-dom';

import { useCodewordTemplate } from '@features/codeword-templates/api/getCodewordTemplate';
import { SettingsForm } from '@features/preferences';

export const SettingsPage = () => {
  const { id } = useParams();
  const { data: codewordTemplate } = useCodewordTemplate(id);
  if (!codewordTemplate) return null;
  return <SettingsForm initialPreferences={codewordTemplate.preferenceSettings} />;
};
