import { forwardRef } from 'react';

import { Box, MantineLoaderComponent } from '@mantine/core';
import cx from 'clsx';

import classes from './CustomLoader.module.css';

// eslint-disable-next-line react/prop-types
export const CustomLoader: MantineLoaderComponent = forwardRef(({ className, ...others }, ref) => (
  <Box component="span" className={cx(classes.loader, className)} {...others} ref={ref} />
));
CustomLoader.displayName = 'CustomLoader';
