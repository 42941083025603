import { IconCalendar, IconClock12, IconCode } from '@tabler/icons-react';

import { CodewordsPage } from './CodewordsPage';
import { PreferredBlocksPage } from './PreferredBlocksPage';
import { SettingsPage } from './SettingsPage';

export const UPDATE_NAV_LINKS = [
  {
    label: 'Codewords',
    description: 'Manage general settings for your codeword template',
    Icon: IconCode,
    relativePath: 'codewords',
    element: <CodewordsPage />,
  },
  {
    label: 'Preferences',
    description: 'Manage preferences for your codeword template',
    Icon: IconClock12,
    relativePath: 'preferences',
    element: <SettingsPage />,
  },
  {
    label: 'Preferred blocks',
    description: 'Manage preferred blocks for your codeword template',
    Icon: IconCalendar,
    relativePath: 'preferred-blocks',
    element: <PreferredBlocksPage />,
  },
];
