import { FC } from 'react';

import { Button, Center, Stack, Text } from '@mantine/core';
import { DatePicker, DatesRangeValue } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { DateTime } from 'luxon';

import { LocationOverride, UpsertLocationOverrideParams } from '../types';

import { LocationOrPointOfInterestInput } from './LocationOrPointOfInterestInput';

interface LocationOverrideFormProps {
  onSubmit: (values: UpsertLocationOverrideParams) => void;
  initialLocationOverride?: LocationOverride;
  errorMessage?: string;
}

export const LocationOverrideForm: FC<LocationOverrideFormProps> = ({
  onSubmit,
  initialLocationOverride,
  errorMessage,
}) => {
  const initialLocation = initialLocationOverride?.location;

  const form = useForm({
    initialValues: {
      locationOrPointOfInterest: {
        location: initialLocation
          ? {
              placeId: initialLocation.googlePlaceId,
              description: initialLocation.description,
            }
          : null,
        pointOfInterestId: initialLocationOverride?.pointOfInterest?.id || null,
      },
      timeWindow: {
        start: null,
        end: null,
        ...initialLocationOverride?.timeWindow,
      },
    },
    validate: (values) => {
      const { location, pointOfInterestId } = values.locationOrPointOfInterest;
      return {
        timeWindow: values.timeWindow.start && values.timeWindow.end ? null : 'Time window is required',
        locationOrPointOfInterest: location || pointOfInterestId ? null : 'Location or point of interest is required',
      };
    },
  });

  const handleTimeRangeChange = (newValue: DatesRangeValue) => {
    const [newStart, newEnd] = newValue;

    const modifiedEnd: DateTime | null = newEnd ? DateTime.fromJSDate(newEnd) : null;

    form.setValues((values) => {
      return { ...values, timeWindow: { start: newStart ? DateTime.fromJSDate(newStart) : null, end: modifiedEnd } };
    });
  };

  const handleSubmit = async (values: typeof form.values) => {
    if (!values.timeWindow.start || !values.timeWindow.end) {
      return;
    }
    onSubmit({
      location: values.locationOrPointOfInterest.location,
      pointOfInterestId: values.locationOrPointOfInterest.pointOfInterestId,
      timeWindow: {
        start: values.timeWindow.start,
        end: values.timeWindow.end,
      },
      id: initialLocationOverride?.id,
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Stack gap="xs">
          <Center>
            <DatePicker
              type="range"
              value={[form.values.timeWindow.start?.toJSDate() || null, form.values.timeWindow.end?.toJSDate() || null]}
              onChange={handleTimeRangeChange}
              minDate={DateTime.now().toJSDate()}
            />
          </Center>
          <Text size="xs" c="dimmed">
            Date range is exclusive of the end date.
          </Text>
        </Stack>
        {form.values.timeWindow.start && form.values.timeWindow.end && (
          <Stack gap="xs">
            <LocationOrPointOfInterestInput
              initialValue={
                initialLocationOverride?.location?.description || initialLocationOverride?.pointOfInterest?.name || ''
              }
              setLocation={(locationOrPointOfInterest) => {
                if (!locationOrPointOfInterest) {
                  form.setValues((values) => {
                    return {
                      ...values,
                      locationOrPointOfInterest: {
                        location: null,
                        pointOfInterestId: null,
                      },
                    };
                  });
                  return;
                }
                form.setValues((values) => {
                  return {
                    ...values,
                    locationOrPointOfInterest: {
                      location: locationOrPointOfInterest.location,
                      pointOfInterestId: locationOrPointOfInterest.pointOfInterestId,
                    },
                  };
                });
              }}
              label="Where will you be those dates?"
              {...form.getInputProps('locationOrPointOfInterest')}
            />
          </Stack>
        )}
        <Button type="submit">Submit</Button>
        {errorMessage && <Text c="red">{errorMessage}</Text>}
      </Stack>
    </form>
  );
};
