import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

const deleteLocationOverride = async (locationOverrideId: string): Promise<void> => {
  await axios.delete(`/location/location-overrides/${locationOverrideId}`);
};

export const useDeleteLocationOverride = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteLocationOverride,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['locationOverrides'],
      });
    },
  });
};
