import { useEffect, useState } from 'react';

import { Select } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconWorld } from '@tabler/icons-react';
import moment from 'moment-timezone';

import { AnalyticsEventName, track } from '@features/analytics';

import { useUpdatePreferences } from '../api/updatePreferences';
import { PreferenceSettings } from '../types';

import { SettingsGrid } from './SettingsGrid';

export const DefaultTimeZone = ({ initialPreferences }: { initialPreferences: PreferenceSettings }) => {
  const { mutate: updatePreferences, isPending: isUpdatingPreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'timeZone', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: 'Default timezone updated', color: 'green', autoClose: 3000 });
    },
  });
  const [defaultTimeZone, setDefaultTimeZone] = useState<string | null>(null);

  // Default timezone to the timezone on preferences on initial load
  useEffect(() => {
    if (initialPreferences.timeZone && !defaultTimeZone) {
      setDefaultTimeZone(initialPreferences.timeZone);
    }
  }, [initialPreferences.timeZone, defaultTimeZone]);

  const handleDefaultTimeZoneChange = (value: string | null) => {
    if (!value || !initialPreferences) {
      // This should never happen, throwing an error
      throw new Error('Value or preferences not found');
    }

    setDefaultTimeZone(value);
    updatePreferences({ ...initialPreferences, timeZone: value });
    return;
  };

  if (!defaultTimeZone) return null;

  return (
    <SettingsGrid
      Icon={IconWorld}
      title="Default Timezone"
      description="We will use it for scheduling if you don't have any trips scheduled."
      left={
        <Select
          data={moment.tz.names().map((tz) => ({
            label: tz.replace('_', ' '),
            value: tz,
          }))}
          value={defaultTimeZone}
          onChange={handleDefaultTimeZoneChange}
          allowDeselect={false}
          disabled={isUpdatingPreferences}
        />
      }
    />
  );
};
