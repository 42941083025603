import React from 'react';

import { Badge, BadgeProps } from '@mantine/core';

interface CountBadgeProps extends Omit<BadgeProps, 'children'> {
  count: number;
}

export const CountBadge: React.FC<CountBadgeProps> = ({ count, ...props }) => {
  return (
    <Badge
      size="lg"
      radius="xl"
      variant="filled"
      color="blue"
      styles={{
        root: {
          width: 24,
          height: 24,
          padding: 0,
          fontWeight: 700,
        },
      }}
      {...props}
    >
      {count}
    </Badge>
  );
};
