import { Avatar, Group, Text } from '@mantine/core';

import { Contact } from '../../types';

interface NameContentProps {
  contact: Contact;
}

export const NameContent = ({ contact }: NameContentProps) => (
  <Group gap="sm" wrap="nowrap">
    {contact.photoUrl && (
      <Avatar src={contact.photoUrl} size="sm">
        {contact.firstName?.[0]}
      </Avatar>
    )}
    <Text truncate="end">{contact.firstName ? `${contact.firstName} ${contact.lastName}` : contact.emails[0]}</Text>
  </Group>
);
