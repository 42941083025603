import { FC, useMemo, useState } from 'react';

import { Select } from '@mantine/core';

import { MINUTES_IN_HOUR } from '@constants/index';

const DEFAULT_RANGE: [number, number] = [15, 60];
const DEFAULT_STEP = 15;

const getOptionsToMinutes = (range: [number, number], step: number, useSingular: boolean) => {
  const options: Record<string, number> = {};
  const [start, end] = range;
  for (let i = start; i <= end; i += step) {
    if (i / MINUTES_IN_HOUR < 1) {
      options[`${i} ${useSingular ? 'minute' : 'minutes'}`] = i;
    } else {
      options[`${i / MINUTES_IN_HOUR} ${useSingular || i === MINUTES_IN_HOUR ? 'hour' : 'hours'}`] = i;
    }
  }
  return options;
};

interface MinutesSelectionProps {
  label?: string;
  initialValue: number;
  onSelect: (value: number) => void;
  isInline?: boolean;
  range?: [number, number];
  step?: number;
  useSingular?: boolean;
  disabled?: boolean;
}

export const MinutesSelection: FC<MinutesSelectionProps> = ({
  initialValue,
  onSelect,
  isInline,
  range = DEFAULT_RANGE,
  step = DEFAULT_STEP,
  useSingular = false,
  disabled,
  label,
}) => {
  const optionsToMinutes = useMemo(() => getOptionsToMinutes(range, step, useSingular), [range, step, useSingular]);

  const minutesToOptions: Record<number, string> = useMemo(() => {
    const tempMinutesToOptions: Record<number, string> = {};
    Object.keys(optionsToMinutes).forEach((key) => {
      tempMinutesToOptions[optionsToMinutes[key]] = key;
    });
    return tempMinutesToOptions;
  }, [optionsToMinutes]);

  const [value, setValue] = useState(minutesToOptions[initialValue]);

  const handleSelect = (value: string | null) => {
    if (!value) return;
    setValue(value);
    onSelect(optionsToMinutes[value as keyof typeof optionsToMinutes]);
  };

  return (
    <Select
      label={label}
      display={isInline ? 'inline-block' : 'block'}
      data={Object.keys(optionsToMinutes)}
      value={value}
      onChange={handleSelect}
      allowDeselect={false}
      pl={isInline ? 'xs' : undefined}
      pr={isInline ? 'xs' : undefined}
      w={isInline ? 150 : undefined}
      disabled={disabled}
      // Making disabled state lighter than normal to support "off" states in preferences
      styles={{
        input: {
          '--input-disabled-color': 'var(--mantine-color-gray-5)',
          '--input-disabled-bg': 'var(--mantine-color-gray-0)',
        },
      }}
    />
  );
};
