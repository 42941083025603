export enum ZoomAccountState {
  ACTIVE = 'active',
  REFRESH_TOKEN_FAILED = 'refresh_token_failed',
  DEAUTHORIZED = 'deauthorized',
}

export interface ZoomAccountDetails {
  id: string;
  userId: string;
  zoomUserId: string;
  email: string;
  state: ZoomAccountState;
}
