import { Loader } from '@mantine/core';
import { Navigate, Outlet } from 'react-router-dom';

import { PageContainer } from '@components/PageContainer';
import { useFirebaseAuthState, useIsInternalUser } from '@features/auth';
import { ConnectBlockBot } from '@features/internal';

import { INTERNAL_ROUTES } from './enums';

// eslint-disable-next-line react-refresh/only-export-components
const Container = () => {
  const isInternal = useIsInternalUser();
  const authState = useFirebaseAuthState();
  if (authState.loading) {
    return (
      <PageContainer>
        <Loader size="xl" />
      </PageContainer>
    );
  }

  if (!isInternal) return <Navigate to="/" />;
  return <Outlet />;
};

export const internalRoutes = [
  {
    path: '/internal',
    element: <Container />,
    children: [{ path: INTERNAL_ROUTES.INTERNAL, element: <ConnectBlockBot /> }],
  },
];
