import React, { useEffect, useState, useRef, useCallback } from 'react';

import { Container, Text, Stack, Group, Loader, Title } from '@mantine/core';
import { motion, AnimatePresence } from 'framer-motion';
import { DateTime } from 'luxon';

import { BlockitLogo } from '@components/BlockitLogo';
import TypewriterText from '@components/TypewriterText';
import { AnalyticsEventName, track, useLogPageView } from '@features/analytics';
import { useGoogleOAuthPopup } from '@features/auth';
import {
  CalendarStats,
  computeMeetingStats,
  getCalendarStatsText,
  isQualifiedUser,
  parseJwtToProfile,
  useFetchCalendarEvents,
} from '@features/waitlist-form';

import { useJoinWaitlist } from '../api/joinWaitlist';

import { GoogleSignInButton } from './GoogleSignInButton';
import { TypewriterLine } from './TypewriterLine';
import styles from './WaitlistIntakePage.module.css';

const ICON_HEIGHT = 24;
const TEXT_NEW_LINE_DELAY = 300;
const TYPEFORM_URL = 'https://form.typeform.com/to/uzHa05zO#cio_status=Lead&source=IntakeForm';
const LOGO_ANIMATION_DURATION_IN_MS = 300;

export const WaitlistIntakePage: React.FC = () => {
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const texts = [
    "Hello, I'm Blockit.",
    "I'm a next generation assistant for your time.",
    "I've been trained to be personalized, reliable, and secure.",
    'Sign in below and I can share back an analysis of how you spend your time.',
  ];
  const { openPopup, error: googleOAuthError, accessToken, idToken } = useGoogleOAuthPopup(false, true);
  const [isConnectCalendarButtonLoading, setIsConnectCalendarButtonLoading] = useState(false);
  const [calendarLoadingStartTime, setCalendarLoadingStartTime] = useState<DateTime | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [additionalTexts, setAdditionalTexts] = useState<string[]>([]);
  const [allTextsRendered, setAllTextsRendered] = useState(false);
  const [currentAdditionalTextIndex, setCurrentAdditionalTextIndex] = useState(0);
  const [waitlistMessage, setWaitlistMessage] = useState<string | null>(null);
  const [showWaitlistMessage, setShowWaitlistMessage] = useState(false);
  const [isPersonalEmail, setIsPersonalEmail] = useState(false);
  const [calendarStats, setCalendarStats] = useState<CalendarStats | null>(null);
  const [showLoadingForWaitlist, setShowLoadingForWaitlist] = useState(false);
  const { mutateAsync: joinWaitlist } = useJoinWaitlist(
    () => {
      setShowWaitlistMessage(true);
      setShowLoadingForWaitlist(false);
      setCurrentLineIndex((prev) => prev + 1);
    },
    () => setError('Sorry, there was an error. Please contact support@blockit.com'),
  );
  const [error, setError] = useState<string | null>(null);

  useLogPageView('Waitlist Intake Form');

  const { data: events } = useFetchCalendarEvents(accessToken ? { accessToken } : undefined, isPersonalEmail);

  const lineRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [iconPosition, setIconPosition] = useState<number | null>(null);

  const updateIconPosition = useCallback(() => {
    const currentRef = lineRefs.current[currentLineIndex];
    if (currentRef) {
      const rect = currentRef.getBoundingClientRect();
      const containerRect = lineRefs.current[0]?.parentElement?.getBoundingClientRect();
      if (containerRect) {
        const relativePosition = rect.top - containerRect.top + (rect.height - ICON_HEIGHT) / 2;
        setIconPosition(relativePosition);
      }
    }
  }, [currentLineIndex]);

  useEffect(() => {
    // Wait for next frame to ensure DOM is ready
    requestAnimationFrame(() => {
      const firstLineRef = lineRefs.current[0];
      if (firstLineRef && firstLineRef.parentElement) {
        const rect = firstLineRef.getBoundingClientRect();
        const containerRect = firstLineRef.parentElement.getBoundingClientRect();
        const initialPosition = rect.top - containerRect.top + (rect.height - ICON_HEIGHT) / 2;
        setIconPosition(initialPosition);
      }
    });
  }, []); // Empty dependency array since we only want this to run once on mount

  useEffect(() => {
    updateIconPosition();
  }, [currentLineIndex, updateIconPosition]);

  useEffect(() => {
    window.addEventListener('resize', updateIconPosition);
    return () => window.removeEventListener('resize', updateIconPosition);
  }, [updateIconPosition]);

  useEffect(() => {
    if (googleOAuthError) {
      setError(googleOAuthError);
    }
  }, [googleOAuthError]);

  useEffect(() => {
    if (idToken) {
      const profile = parseJwtToProfile(idToken);
      if (profile) {
        setUserEmail(profile.email);
        setFirstName(profile.firstName);
        setLastName(profile.lastName);

        if (!isCompanyEmail(profile.email)) {
          setIsPersonalEmail(true);
          setIsConnectCalendarButtonLoading(false);
          return;
        }
        setIsPersonalEmail(false);

        setIsConnectCalendarButtonLoading(true);
        setCalendarLoadingStartTime(DateTime.now());
      }
    }
  }, [idToken]);

  useEffect(() => {
    if (events && userEmail && !isPersonalEmail) {
      const meetingStats = computeMeetingStats(events, userEmail);
      setCalendarStats(meetingStats);
      if (isQualifiedUser(meetingStats)) {
        setWaitlistMessage("I've added you to the waitlist and I'll be in touch shortly to get you started 😄");
      } else {
        setWaitlistMessage("I've added you to the waitlist for once I'm ready to start supporting more people 😄");
      }

      const calendarStatsTexts = getCalendarStatsText(meetingStats);
      setAdditionalTexts([`Hello ${firstName}!`, ...calendarStatsTexts]);

      const elapsedTime = DateTime.now()
        .diff(calendarLoadingStartTime ?? DateTime.now())
        .toMillis();
      const delay = Math.max(0, 2000 - elapsedTime);

      setTimeout(() => {
        setIsConnectCalendarButtonLoading(false);
        setCurrentLineIndex((prev) => prev + 1);
      }, delay);
    }
  }, [calendarLoadingStartTime, events, userEmail, firstName, isPersonalEmail]);

  const isCompanyEmail = (email: string): boolean => {
    const personalDomains = ['gmail.com', 'me.com', 'outlook.com', 'hotmail.com', 'yahoo.com'];
    const domain = email.split('@')[1];
    return !personalDomains.includes(domain);
  };

  const handleGoogleLogin = () => {
    setError(null);
    openPopup();
  };

  const handleLineComplete = () => {
    setTimeout(() => {
      setCurrentLineIndex((prev) => prev + 1);
    }, TEXT_NEW_LINE_DELAY);
  };

  const handleAdditionalTextComplete = () => {
    setTimeout(() => {
      if (currentAdditionalTextIndex < additionalTexts.length - 1) {
        setCurrentAdditionalTextIndex((prev) => prev + 1);
        setCurrentLineIndex((prev) => prev + 1);
      } else if (!allTextsRendered) {
        setAllTextsRendered(true);
        if (calendarStats) {
          setShowLoadingForWaitlist(true);
          joinWaitlist({
            calendarStats,
            email: userEmail ?? '',
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            isQualified: isQualifiedUser(calendarStats),
          });
          track({
            type: AnalyticsEventName.WaitlistButtonClicked,
            data: {
              email: userEmail ?? '',
              isQualified: isQualifiedUser(calendarStats),
              numExternalMeetingsCreated: calendarStats.numExternalMeetingsCreated,
            },
          });
        }
      }
    }, TEXT_NEW_LINE_DELAY);
  };

  const GoogleSignInOrTypeform: React.FC<{ onGoogleSignIn: () => void }> = ({ onGoogleSignIn }) => (
    <>
      <Group mt="lg">
        <GoogleSignInButton onClick={onGoogleSignIn} />
      </Group>
      <Title order={6} fw={400} c="blue.8" mt="0">
        Or fill out <a href={TYPEFORM_URL}>this typeform</a> to get on the waitlist!
      </Title>
    </>
  );

  return (
    <Container size="md" pt="xl">
      <Stack my="xl" ml="12px" pl="xl" pr="lg" className={styles.stackContainer}>
        <AnimatePresence mode="wait">
          {iconPosition !== null && (
            <motion.div
              key="blockit-logo"
              initial={{ y: iconPosition }}
              animate={{ y: iconPosition }}
              transition={{ duration: LOGO_ANIMATION_DURATION_IN_MS / 1000 }}
              className={styles.iconContainer}
            >
              <BlockitLogo size={ICON_HEIGHT} />
            </motion.div>
          )}
        </AnimatePresence>

        {texts.slice(0, currentLineIndex + 1).map((text, index) => (
          <Title
            key={index}
            ref={(el) => (lineRefs.current[index] = el)}
            order={index === 0 ? 1 : 3}
            c="blue.8"
            fw={index === 0 ? 700 : 500}
            mt="lg"
            component="div"
            className={styles.titleContainer}
          >
            <TypewriterLine
              text={text}
              onComplete={index === currentLineIndex ? handleLineComplete : undefined}
              delay={LOGO_ANIMATION_DURATION_IN_MS + 100}
            />
          </Title>
        ))}

        {currentLineIndex === texts.length && !accessToken && (
          <GoogleSignInOrTypeform onGoogleSignIn={handleGoogleLogin} />
        )}
        {isPersonalEmail && (
          <>
            <Title order={5} c="red.8" fw={400} mt="lg">
              Please sign in with your company email to continue.
            </Title>
            <GoogleSignInOrTypeform onGoogleSignIn={handleGoogleLogin} />
          </>
        )}
        {isConnectCalendarButtonLoading && (
          <Group mt="lg">
            <Loader size="md" />
            <Text c="blue.8" component="div">
              Analyzing your calendar
              <TypewriterText text="......" delay={250} />
            </Text>
          </Group>
        )}
        {!isConnectCalendarButtonLoading &&
          additionalTexts.length > 0 &&
          additionalTexts.slice(0, currentAdditionalTextIndex + 1).map((text, index) => (
            <Title
              key={`additional-${index}`}
              ref={(el) => (lineRefs.current[texts.length + index + 1] = el)}
              order={3}
              c="blue.8"
              fw={index === 0 ? 700 : 500}
              mt="lg"
              component="div"
              className={styles.titleContainer}
            >
              <TypewriterLine
                text={text}
                onComplete={index === currentAdditionalTextIndex ? handleAdditionalTextComplete : undefined}
                delay={LOGO_ANIMATION_DURATION_IN_MS + 100}
              />
            </Title>
          ))}
        {allTextsRendered && showLoadingForWaitlist && (
          <Group>
            <Loader size="md" />
          </Group>
        )}
        {waitlistMessage && showWaitlistMessage && (
          <Title
            order={3}
            c="blue.8"
            mt="lg"
            fw={400}
            ref={(el) => (lineRefs.current[texts.length + additionalTexts.length + 1] = el)}
          >
            <TypewriterLine text={waitlistMessage} delay={LOGO_ANIMATION_DURATION_IN_MS + 100} />
          </Title>
        )}
        {error && (
          <Text c="red" size="lg">
            Error: {error}
          </Text>
        )}
      </Stack>
    </Container>
  );
};
