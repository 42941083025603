import { FC } from 'react';

import { signOut } from '@firebase/auth';
import { NavLink } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';

import { firebaseAuth } from '../config/firebaseAuth';

export const SignOutButton: FC = () => {
  return (
    <NavLink
      component="button"
      onClick={() => signOut(firebaseAuth)}
      leftSection={<IconLogout size="20px" />}
      label="Sign Out"
    />
  );
};
