export const formatFirebaseError = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/invalid-verification-code':
      return 'Invalid code';
    case 'auth/code-expired':
      return 'Code expired';
    case 'auth/too-many-requests':
      return 'Too many requests. Try again later.';
    case 'auth/invalid-phone-number':
      return 'Invalid phone number';
    default:
      return 'Unable to process request.';
  }
};
