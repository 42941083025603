import { notifications } from '@mantine/notifications';
import type { InternalAxiosRequestConfig } from 'axios';
import Axios, { AxiosError } from 'axios';

import { getCurrentUser } from '@features/auth/utils/getCurrentUser';

const getToken = async (): Promise<string | null> => {
  const user = getCurrentUser();
  if (user) {
    return user.getIdToken();
  } else {
    return null;
  }
};

const authRequestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const token = await getToken();
  if (token) {
    config.headers.authorization = `${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
};

export const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
});

axios.interceptors.request.use(authRequestInterceptor);

interface RequestErrorMessage {
  message?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class RequestError extends AxiosError<RequestErrorMessage, any> {}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: RequestError) => {
    if (!error.config?.params?.silent) {
      const message = error.response?.data?.message || 'Unable to process request.';
      notifications.show({
        title: 'Error',
        message: message,
        color: 'red',
      });
    }

    return Promise.reject(error);
  },
);

export const externalAxios = Axios.create();
