import { Box, Table, Text } from '@mantine/core';
import { useEventListener } from '@mantine/hooks';

import { ContactTableColumn, SortField, SortState } from '../types';

import { SortableHeader } from './SortableHeader';
import classes from './TableHeaderColumn.module.css';

interface TableHeaderColumnProps {
  column: {
    key: ContactTableColumn;
    width: number;
    title: string;
    sortable?: boolean;
  };
  sort?: SortState;
  onSort?: (field: SortField) => void;
  onResize: (column: ContactTableColumn, width: number) => void;
  isSticky?: boolean;
}

export const TableHeaderColumn = ({ column, sort, onSort, onResize, isSticky }: TableHeaderColumnProps) => {
  const headerClass = isSticky ? classes.stickyHeader : classes.header;

  const handleResize = (event: MouseEvent) => {
    const startX = event.clientX;
    const startWidth = column.width;

    const handleMouseMove = (e: MouseEvent) => {
      const diff = e.clientX - startX;
      onResize(column.key, Math.max(100, startWidth + diff));
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const resizeRef = useEventListener('mousedown', handleResize);

  return (
    <Table.Th className={headerClass} w={column.width} p={0}>
      <Box className={classes.headerContent}>
        {column.sortable && sort && onSort ? (
          <SortableHeader
            field={column.key as SortField}
            activeField={sort.field}
            direction={sort.direction}
            onSort={onSort}
            title={column.title}
          />
        ) : (
          <Text size="sm" fw={500}>
            {column.title}
          </Text>
        )}
      </Box>
      <Box ref={resizeRef} className={classes.resize} />
    </Table.Th>
  );
};
