import { Badge, BadgeProps } from '@mantine/core';

import { LocationType } from '../types';

import { LocationTypeIcon } from './LocationTypeIcon';

interface LocationTypeBadgeProps extends BadgeProps {
  locationType: LocationType;
  iconSize?: string;
}
export const LocationTypeBadge = ({ locationType, iconSize = '16px', ...props }: LocationTypeBadgeProps) => {
  return (
    <Badge leftSection={<LocationTypeIcon locationType={locationType} size={iconSize} />} variant="light" {...props}>
      {locationType}
    </Badge>
  );
};
