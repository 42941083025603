import { Divider, Stack } from '@mantine/core';

import { PreferenceSettings } from '../types';

import { DefaultTimeZone } from './DefaultTimeZone';
import { MaxMeetingMinutes } from './MaxMeetingMinutes';
import { MeetingBuffers } from './MeetingBuffers';
import { MeetingDuration } from './MeetingDuration';
import { MeetingNudge } from './MeetingNudge';
import { TravelTime } from './TravelTime';
import { VirtualMeetings } from './VirtualMeetings';

export const SettingsForm = ({ initialPreferences }: { initialPreferences: PreferenceSettings }) => {
  return (
    <Stack gap="xl" mb="xl">
      <VirtualMeetings initialPreferences={initialPreferences} />
      <Divider />
      <MeetingNudge initialPreferences={initialPreferences} />
      <Divider />
      <TravelTime initialPreferences={initialPreferences} />
      <Divider />
      <MeetingBuffers initialPreferences={initialPreferences} />
      <Divider />
      <MaxMeetingMinutes initialPreferences={initialPreferences} />
      <Divider />
      <MeetingDuration initialPreferences={initialPreferences} />
      <Divider />
      <DefaultTimeZone initialPreferences={initialPreferences} />
    </Stack>
  );
};
