import { Stack, Text } from '@mantine/core';

import { SideContent } from '@components/SideContent';

import { MovableEventsSection } from './MovableEventsSection';
import { SkippableEventsSection } from './SkippableEventsSection';

export function FlexibilityPage() {
  return (
    <SideContent
      sideContent={
        <Text c="dimmed">
          Mark events as flexible so that Blockit can automatically move or schedule over them when necessary.
        </Text>
      }
    >
      <Stack gap="xl">
        <MovableEventsSection />
        <SkippableEventsSection />
      </Stack>
    </SideContent>
  );
}
