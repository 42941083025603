import React from 'react';

import { Select, SelectProps } from '@mantine/core';

import { TimeOfDay } from '@/types';
import { HOURS_IN_DAY, MINUTES_IN_HOUR } from '@constants/index';

interface TimeInputSelectProps extends Omit<SelectProps, 'data' | 'onChange'> {
  value: TimeOfDay;
  onChange: (value: TimeOfDay) => void;
}

const TIME_INCREMENT_IN_MINUTES = 15;

const generateTimeOptions = (): { value: string; label: string }[] => {
  const options = [];

  for (let hour = 0; hour < HOURS_IN_DAY; hour++) {
    for (let minute = 0; minute < MINUTES_IN_HOUR; minute += TIME_INCREMENT_IN_MINUTES) {
      const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      const displayHour = hour % 12 || 12;
      const amPm = hour < 12 ? 'AM' : 'PM';
      const label = `${displayHour}:${minute.toString().padStart(2, '0')} ${amPm}`;
      options.push({ value, label });
    }
  }
  return options;
};

export const TimeInputSelect: React.FC<TimeInputSelectProps> = ({ value, onChange, ...props }) => {
  const timeOptions = React.useMemo(() => generateTimeOptions(), []);

  const handleChange: SelectProps['onChange'] = (newValue) => {
    if (newValue) {
      onChange(newValue as TimeOfDay);
    }
  };

  return (
    <Select data={timeOptions} value={value} onChange={handleChange} searchable allowDeselect={false} {...props} />
  );
};
