import { useParams } from 'react-router-dom';

import { useCodewordTemplate } from '@features/codeword-templates/api/getCodewordTemplate';

import { CodewordsForm } from './CodewordsForm';

export const CodewordsPage = () => {
  const { id } = useParams();
  const { data: codewordTemplate } = useCodewordTemplate(id);

  if (!codewordTemplate || !id) {
    return null;
  }

  return <CodewordsForm initialCodewordTemplate={codewordTemplate} />;
};
