import React, { useEffect, useState } from 'react';

import { Autocomplete, CloseButton, Group, Text } from '@mantine/core';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

import { GooglePlaceInfo } from '@/types';

import { usePointsOfInterest } from '../api/getPointsOfInterest';
import { PointOfInterest } from '../types';

import { LocationTypeBadge } from './LocationTypeBadge';

interface LocationOrPointOfInterestInputProps {
  initialValue?: string;
  setLocation: (
    inputs: {
      location: GooglePlaceInfo | null;
      pointOfInterestId: string | null;
    } | null,
  ) => void;
  error?: string;
  label?: string;
  description?: string;
  required?: boolean;
}

export const LocationOrPointOfInterestInput: React.FC<LocationOrPointOfInterestInputProps> = (props) => {
  const { setLocation, error, label, description, required } = props;
  const places = useMapsLibrary('places');
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | null>(null);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const { data: pointsOfInterest } = usePointsOfInterest();
  const [pointOfInterestOptions, setPointOfInterestOptions] = useState<PointOfInterest[]>([]);

  const [locationOptions, setLocationOptions] = useState<GooglePlaceInfo[]>([]);
  const [value, setValue] = useState<string>(props.initialValue ?? '');

  useEffect(() => {
    if (!places) return;

    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [places]);

  if (!places || !autocompleteService || !sessionToken || !pointsOfInterest) {
    return null;
  }

  const handleChange = (value: string) => {
    // We need to set a new session token once a selection has been made
    setSessionToken(new places.AutocompleteSessionToken());
    const pointOfInterest = pointOfInterestOptions.find((option) => option.name === value);
    if (pointOfInterest) {
      setLocation({ location: null, pointOfInterestId: pointOfInterest.id });
      setValue(pointOfInterest.name);
      return;
    }
    const option = locationOptions.find((option) => option.description === value);

    if (!option) {
      setLocation(null);
      return;
    }
    setValue(option.description);
    setLocation({ location: option, pointOfInterestId: null });
  };

  const handleInputChange = async (newInputValue: string) => {
    setValue(newInputValue);
    // When the input changes, we need to make a call to places autocomplete service
    // to get a new set of locationOptions

    if (newInputValue === '') {
      setLocationOptions([]);
      setPointOfInterestOptions([]);
      return undefined;
    }
    setPointOfInterestOptions(
      pointsOfInterest.filter(
        (pointOfInterest) =>
          pointOfInterest.name.toLowerCase().startsWith(newInputValue.toLowerCase()) ||
          pointOfInterest.location.description.toLowerCase().startsWith(newInputValue.toLowerCase()),
      ),
    );
    const token = new places.AutocompleteSessionToken();
    const { predictions } = await autocompleteService.getPlacePredictions({
      input: newInputValue,
      sessionToken: token,
    });

    const newOptions = predictions
      .filter((prediction) => prediction.place_id)
      .filter(
        (prediction) => !pointOfInterestOptions.some((poi) => poi.location.description === prediction.description),
      )
      .map((prediction) => ({
        placeId: prediction.place_id,
        description: prediction.description,
      }));
    setLocationOptions(newOptions);
  };
  return (
    <Autocomplete
      error={error}
      description={description}
      /* Setting type search so password managers do not try to fill */
      type="search"
      data={[
        {
          group: 'Point of Interest',
          items: pointOfInterestOptions.map((option) => option.name),
        },
        {
          group: 'Address',
          items: locationOptions.map((option) => (typeof option === 'string' ? option : option.description)),
        },
      ]}
      onOptionSubmit={handleChange}
      onChange={handleInputChange}
      value={value}
      label={label}
      required={required}
      /* Having filter do nothing so that filtering is handled via google maps */
      filter={({ options }) => options}
      renderOption={({ option }) => {
        const pointOfInterest = pointOfInterestOptions.find(
          (poi) => poi.name.toLowerCase() === option.value.toLowerCase(),
        );
        if (pointOfInterest) {
          return (
            <Group>
              <LocationTypeBadge locationType={pointOfInterest.locationType} />
              <Text size="sm">{pointOfInterest.name}</Text>
            </Group>
          );
        }
        return <Text size="sm">{option.value}</Text>;
      }}
      rightSection={
        <CloseButton
          size="sm"
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => {
            setValue('');
            setLocationOptions([]);
            setLocation(null);
            setSessionToken(new places.AutocompleteSessionToken());
          }}
          aria-label="Clear value"
        />
      }
    />
  );
};
