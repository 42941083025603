import { Box, Table, Text } from '@mantine/core';

import { Contact, ContactTableColumn, Organization } from '../types';

import { ActionsContent } from './cell-content/ActionsContent';
import { EmailsContent } from './cell-content/EmailsContent';
import { InfoContent } from './cell-content/InfoContent';
import { NameContent } from './cell-content/NameContent';
import { OrganizationContent } from './cell-content/OrganizationContent';
import { TitleContent } from './cell-content/TitleContent';
import classes from './TableCell.module.css';

interface TableCellProps {
  columnKey: ContactTableColumn;
  width: number;
  isSticky?: boolean;
  contact: Contact;
  filters?: {
    organizationIds?: string[];
  };
  organizationsById: Record<string, Organization>;
  onEdit?: () => void;
}

export const TableCell = ({
  columnKey,
  width,
  isSticky,
  contact,
  filters,
  organizationsById,
  onEdit,
}: TableCellProps) => {
  const className = isSticky ? classes.stickyCell : undefined;

  const renderContent = () => {
    switch (columnKey) {
      case ContactTableColumn.NAME:
        return <NameContent contact={contact} />;

      case ContactTableColumn.ORGANIZATION:
        return <OrganizationContent contact={contact} filters={filters} organizationsById={organizationsById} />;

      case ContactTableColumn.TITLE:
        return <TitleContent contact={contact} />;

      case ContactTableColumn.EMAILS:
        return <EmailsContent contact={contact} />;

      case ContactTableColumn.INFO:
        return <InfoContent contact={contact} />;

      case ContactTableColumn.LAST_MEETING:
        return <Text truncate="end">{contact.lastMeeting?.toRelative() ?? '-'}</Text>;

      case ContactTableColumn.LOCATION:
        return <Text truncate="end">{contact.location?.split(',')[0] ?? '-'}</Text>;

      case ContactTableColumn.ACTIONS:
        return <ActionsContent onEdit={onEdit} />;

      default:
        return null;
    }
  };

  return (
    <Table.Td className={className} w={width}>
      <Box>{renderContent()}</Box>
    </Table.Td>
  );
};
