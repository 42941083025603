import React, { useState } from 'react';

import { Box, Button, Stack, Text } from '@mantine/core';
import { DateTime } from 'luxon';

import { SideContent } from '@components/SideContent';

import { PastMeetingsPage } from './PastMeetingsPage';
import { UpcomingMeetings } from './UpcomingMeetings';

export const MeetingPage: React.FC = () => {
  const [isUpcomingSelected, setIsUpcomingSelected] = useState(true);

  return (
    <SideContent
      sideContent={
        <Stack gap="xs">
          <Text c="dimmed">This is a list of all your upcoming and historical meetings scheduled by Blockit.</Text>
          <Button
            variant={isUpcomingSelected ? 'light' : 'subtle'}
            c="black"
            onClick={() => setIsUpcomingSelected(true)}
            justify="start"
          >
            Upcoming
          </Button>
          <Button
            variant={isUpcomingSelected ? 'subtle' : 'light'}
            c="black"
            onClick={() => setIsUpcomingSelected(false)}
            justify="start"
          >
            Past
          </Button>
        </Stack>
      }
    >
      <Box>
        <Stack gap="md">
          {isUpcomingSelected ? (
            <UpcomingMeetings start={DateTime.now()} end={DateTime.now().plus({ years: 5 })} />
          ) : (
            <PastMeetingsPage />
          )}
        </Stack>
      </Box>
    </SideContent>
  );
};
