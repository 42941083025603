import { FC } from 'react';

import { Box, Button, Stack, Text, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { useGoogleOAuthPopup } from '@features/auth';

export const ConnectBlockBot: FC = () => {
  const { openPopup, error } = useGoogleOAuthPopup(true);
  return (
    <Stack>
      <Title>Connect BlockBot</Title>
      <Button onClick={() => openPopup()} variant="outline" leftSection={<IconPlus size={18} />}>
        Connect BlockBot
      </Button>
      {error && (
        <Box mt="sm">
          {error && (
            <Text c="red" size="xs">
              {error}
            </Text>
          )}
        </Box>
      )}
    </Stack>
  );
};
