import { FC } from 'react';

import { Burger, Group } from '@mantine/core';

import { HomeLinkLogo } from './HomeLinkLogo';

interface MobileHeaderProps {
  opened: boolean;
  toggle: () => void;
}

export const MobileHeader: FC<MobileHeaderProps> = ({ opened, toggle }) => {
  return (
    <Group justify="space-between" pb="md">
      <HomeLinkLogo size={31} />
      <Burger opened={opened} onClick={toggle} aria-label="Toggle navigation" />
    </Group>
  );
};
