import { Card, Stack, Text, Title } from '@mantine/core';

import { PageContainer } from './PageContainer';

export const ErrorFallback = () => {
  return (
    <PageContainer>
      <Card radius="md" p="xl" shadow="sm">
        <Stack>
          <Title ta="center">Oh no! Something went wrong.</Title>
          <Text ta="center">Our team has been notified of the issue and is working to fix it as soon as possible.</Text>
        </Stack>
      </Card>
    </PageContainer>
  );
};
