import React from 'react';

import { Stack } from '@mantine/core';

import { MeetingForDisplay } from '../types';

import { MeetingItem } from './MeetingItem';

interface MeetingListProps {
  meetings: MeetingForDisplay[];
}

export const MeetingList: React.FC<MeetingListProps> = ({ meetings }) => {
  return (
    <Stack gap="xs">
      {meetings.map((meeting) => (
        <MeetingItem key={meeting.id} meeting={meeting} />
      ))}
    </Stack>
  );
};
