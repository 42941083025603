import { DateTime } from 'luxon';

const DATE_FORMAT = 'ccc MMM d';
const DATETIME_FORMAT = 'ccc MMM d t ZZZZ';

export const formatDatetime = (datetime: DateTime, timeZone: string, includeTime: boolean) => {
  if (includeTime) {
    return datetime.setZone(timeZone).toFormat(DATETIME_FORMAT);
  }
  return datetime.setZone(timeZone).toFormat(DATE_FORMAT);
};

export const formatStart = (datetime: DateTime, timeZone: string) => {
  if (datetime.diff(datetime.setZone(timeZone).startOf('day'), 'minutes').minutes <= 1) {
    return formatDatetime(datetime, timeZone, false);
  }
  return formatDatetime(datetime, timeZone, true);
};

export const formatEnd = (datetime: DateTime, timeZone: string) => {
  const datetimeInTimeZone = datetime.setZone(timeZone);
  if (datetimeInTimeZone.endOf('day').diff(datetimeInTimeZone, 'minutes').minutes <= 1) {
    // if the end of the day is within 1 minute of the datetime, format the date without the time
    return formatDatetime(datetime, timeZone, false);
  } else if (datetime.diff(datetimeInTimeZone.startOf('day'), 'minutes').minutes <= 1) {
    // if the start of the day is within 1 minute of the datetime, we want to display the previous day's end
    return formatDatetime(datetimeInTimeZone.minus({ days: 1 }).endOf('day'), timeZone, false);
  }
  return formatDatetime(datetime, timeZone, true);
};
