import { ActionIcon, Group } from '@mantine/core';
import { IconBrandLinkedin, IconMail } from '@tabler/icons-react';

import { Contact } from '../../types';

interface InfoContentProps {
  contact: Contact;
}

export const InfoContent = ({ contact }: InfoContentProps) => (
  <Group gap="0" wrap="nowrap">
    {contact.linkedInUrl && (
      <ActionIcon variant="subtle" component="a" href={contact.linkedInUrl} target="_blank" rel="noopener noreferrer">
        <IconBrandLinkedin size={16} />
      </ActionIcon>
    )}
    {contact.emails.length > 0 && (
      <ActionIcon
        variant="subtle"
        component="a"
        href={`mailto:${contact.emails[0]}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconMail size={16} />
      </ActionIcon>
    )}
  </Group>
);
