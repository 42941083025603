import { Badge, Button, Group, Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { useZoomAccounts } from '../api/getZoomAccounts';
import { ZoomAccountState } from '../types';

export function ZoomIntegrationContainer() {
  const { data: zoomAccounts } = useZoomAccounts();

  const handleConnectZoom = () => {
    // Redirect to Zoom OAuth page
    // You should replace this URL with your actual Zoom OAuth URL
    const zoomOAuthUrl =
      // eslint-disable-next-line max-len
      `https://zoom.us/oauth/authorize?response_type=code&client_id=${import.meta.env.VITE_ZOOM_CLIENT_ID}&redirect_uri=${window.location.origin}/zoom/callback`;
    window.location.href = zoomOAuthUrl;
  };

  const getStateColor = (state: ZoomAccountState) => {
    switch (state) {
      case ZoomAccountState.ACTIVE:
        return 'green';
      case ZoomAccountState.REFRESH_TOKEN_FAILED:
        return 'yellow';
      case ZoomAccountState.DEAUTHORIZED:
        return 'red';
    }
  };

  if (!zoomAccounts) {
    return null;
  }

  return (
    <Stack>
      {zoomAccounts && zoomAccounts.length > 0 ? (
        <>
          <Text fw={500}>Connected Zoom accounts:</Text>
          {zoomAccounts.map((account, index) => (
            <Group key={index} gap="xs">
              <Badge variant="dot" color={getStateColor(account.state)} size="lg">
                {account.email}
              </Badge>
            </Group>
          ))}
        </>
      ) : (
        <Text>No Zoom accounts connected.</Text>
      )}
      <Button variant="outline" onClick={handleConnectZoom} leftSection={<IconPlus size={16} />}>
        {zoomAccounts && zoomAccounts.length > 0 ? 'Add another Zoom account' : 'Add Zoom account'}
      </Button>
    </Stack>
  );
}
