import { DateTime } from 'luxon';

import { MINUTES_IN_HOUR } from '@constants/index';

export const formatTimeRange = (start: string, end: string): string => {
  const formatTime = (time: string) => {
    const dateTime = DateTime.fromFormat(time, 'HH:mm');
    return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  };

  return `${formatTime(start)} - ${formatTime(end)}`;
};

export function formatDuration(minutes: number, isAbbreviated = false): string {
  if (minutes < MINUTES_IN_HOUR) {
    return `${minutes} ${isAbbreviated ? 'min' : 'minute'}${minutes !== 1 ? 's' : ''}`;
  }
  const hours = minutes / MINUTES_IN_HOUR;
  return `${hours} ${isAbbreviated ? 'hr' : 'hour'}${hours > 1 ? 's' : ''}`;
}
