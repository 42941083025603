import React, { forwardRef } from 'react';

import { ThemeIcon, ThemeIconProps, Tooltip } from '@mantine/core';

interface TooltipIconProps extends ThemeIconProps {
  title?: string;
  icon: React.ReactNode;
}

const TooltipIconComponent = forwardRef<HTMLDivElement, TooltipIconProps>((props, ref) => {
  const { title, icon, ...otherProps } = props;

  const iconWrapper = (
    <ThemeIcon variant="transparent" size="xs" c="black" {...otherProps} ref={ref}>
      {icon}
    </ThemeIcon>
  );

  return title ? (
    <Tooltip
      label={title}
      transitionProps={{ transition: 'fade', duration: 300 }}
      position="bottom"
      arrowSize={6}
      withArrow
    >
      {iconWrapper}
    </Tooltip>
  ) : (
    iconWrapper
  );
});

export const TooltipIcon = TooltipIconComponent;
TooltipIconComponent.displayName = 'TooltipIcon';
