import { Card, Stack, Title } from '@mantine/core';

import { ConnectCalendarButton } from './ConnectCalendarButton';

export const ConnectAnotherCard = () => {
  return (
    <Card padding="xl" radius="lg">
      <Stack>
        <Title order={1} size="h2">
          Connect another calendar?
        </Title>
        <ConnectCalendarButton />
      </Stack>
    </Card>
  );
};
