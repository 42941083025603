import { Anchor, Group, Popover, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { Contact } from '../../types';

interface EmailsContentProps {
  contact: Contact;
}

export const EmailsContent = ({ contact }: EmailsContentProps) => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const hasMultipleEmails = contact.emails.length > 1;

  return (
    <Stack gap={4}>
      <Group gap={4} wrap="nowrap">
        <Text size="sm" truncate="end">
          {contact.emails[0]}
        </Text>
        {hasMultipleEmails && (
          <Popover position="bottom" shadow="md" opened={opened} onChange={close} withArrow>
            <Popover.Target>
              <Anchor onClick={toggle} size="xs" fw={700}>{`+${contact.emails.length - 1}`}</Anchor>
            </Popover.Target>

            <Popover.Dropdown>
              <Stack gap="xs">
                {contact.emails.slice(1).map((email) => (
                  <Text size="sm" key={email}>
                    {email}
                  </Text>
                ))}
              </Stack>
            </Popover.Dropdown>
          </Popover>
        )}
      </Group>
    </Stack>
  );
};
