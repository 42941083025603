export const getDomainWithoutExtension = (email: string) => {
  const parts = email.split('@');
  if (parts.length === 2) {
    const domainParts = parts[1].split('.');
    const middleDomain = domainParts.length > 1 ? domainParts[0] : parts[1];
    const commonProviders = ['gmail', 'hotmail', 'me', 'outlook', 'yahoo', 'icloud'];
    return commonProviders.includes(middleDomain.toLowerCase()) ? '' : middleDomain;
  }
  return '';
};
