import { FC } from 'react';

import { Box, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconCalendarOff } from '@tabler/icons-react';

import { AccountWithCalendars } from '../types';

interface AccountDetailsProps {
  account: AccountWithCalendars;
}

export const RevokedAccountDetails: FC<AccountDetailsProps> = ({ account }) => {
  return (
    <Stack>
      <Group gap="xs">
        <ThemeIcon color="red" variant="transparent">
          <IconCalendarOff />
        </ThemeIcon>
        <Text key={account.email}>{account.email}</Text>
      </Group>
      <Box ml={38}>
        <Text size="sm" fs="italic">
          We no longer have access to this account. Please add calendar again to grant access.
        </Text>
      </Box>
    </Stack>
  );
};
