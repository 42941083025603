import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { Contact, CreateContactDTO } from '../types';

const upsertContact = async (data: CreateContactDTO): Promise<Contact> => {
  const { data: response } = await axios.post<Contact>('/contacts', data);
  return response;
};

export const useUpsertContact = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: upsertContact,
    onSuccess: () => {
      // Invalidate the contacts query to refetch the updated list
      queryClient.invalidateQueries({ queryKey: ['contacts'] });
      notifications.show({
        title: 'Success',
        message: 'Contact saved successfully',
        color: 'green',
      });
    },
  });
};

// Keep the old function name as an alias for backward compatibility
export const useCreateContact = useUpsertContact;
