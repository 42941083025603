import { useState } from 'react';

import { Box, Flex, Group, Paper, Space, Stack, Transition } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconArrowBarLeft, IconArrowBarRight, IconLogout } from '@tabler/icons-react';

import { SignOutButton, signOut } from '@features/auth';

import { HomeLinkLogo } from './HomeLinkLogo';
import { NavLinks } from './NavLinks';
import classes from './SideNav.module.css';
import { TooltipActionIcon } from './TooltipActionIcon';

const EXPANDED_NAV_WIDTH = 248;
const COLLAPSED_NAV_WIDTH = 74;

export const SideNav = () => {
  const [isCollapsed, { toggle, close }] = useDisclosure();
  const [isHovered, { close: setNotHovered, open: setHovered }] = useDisclosure();
  const width = isCollapsed ? COLLAPSED_NAV_WIDTH : EXPANDED_NAV_WIDTH;

  const [showCollapsedIcons, setShowCollapsedIcons] = useState(false);

  const showBlockitLogo = !isHovered || !showCollapsedIcons;
  const showExpandIcon = isHovered || !showCollapsedIcons;

  const handleTransitionExited = () => {
    setShowCollapsedIcons(true);
  };

  const handleExpand = () => {
    // We have to special handle the expand to not show the collapsed icons before the transition to fade in
    // the expanded nav links starts
    close();
    setShowCollapsedIcons(false);
  };

  return (
    <Paper
      h="100%"
      radius="lg"
      p="lg"
      maw={width}
      miw={width}
      className={classes.sideNav}
      visibleFrom="lg"
      onMouseEnter={setHovered}
      onMouseLeave={setNotHovered}
    >
      <Box h="100%" w="100%" display="grid">
        <Stack
          gap={isCollapsed ? 'md' : 'xs'}
          align={showCollapsedIcons ? 'center' : undefined}
          w="100%"
          className={classes.stackTransition}
        >
          <Group justify="space-between" h={31}>
            {showBlockitLogo && (
              <Box ml={isCollapsed ? 0 : 5} className={classes.blockitLogoTransition} display="block">
                <HomeLinkLogo size={31} />
              </Box>
            )}
            {showExpandIcon && (
              <Box h={38}>
                <TooltipActionIcon
                  onClick={isCollapsed ? handleExpand : toggle}
                  icon={showCollapsedIcons ? <IconArrowBarRight /> : <IconArrowBarLeft />}
                />
              </Box>
            )}
          </Group>
          {!showCollapsedIcons && <Space />}
          <Transition
            mounted={!isCollapsed}
            transition="fade"
            duration={250}
            exitDuration={500}
            timingFunction="ease-in"
            onExited={handleTransitionExited}
          >
            {(styles) => (
              <Box style={styles}>
                <NavLinks />
              </Box>
            )}
          </Transition>
          {showCollapsedIcons && <NavLinks isCollapsed />}
        </Stack>
        <Flex className={classes.signOutButtonContainer} justify={showCollapsedIcons ? 'center' : undefined}>
          <Transition
            mounted={!isCollapsed}
            transition="fade"
            duration={250}
            exitDuration={500}
            timingFunction="ease-in"
          >
            {(styles) => (
              <Box style={styles} w="100%">
                <SignOutButton />
              </Box>
            )}
          </Transition>
          {showCollapsedIcons && (
            <TooltipActionIcon onClick={signOut} variant="transparent" icon={<IconLogout />} title="Sign Out" />
          )}
        </Flex>
      </Box>
    </Paper>
  );
};
