import { useState } from 'react';

import { Group, Paper, Text } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';

import { Switch } from '@components/Switch';

import { useMarkAsFlexible } from '../api/markAsFlexible';
import { useUnmarkAsFlexible } from '../api/unmarkAsFlexible';
import { FlexibleEvent } from '../types';
import { getRecurrenceText } from '../utils/formatRecurrence';

interface FlexibleEventItemProps {
  event: FlexibleEvent;
}

export function FlexibleEventItem({ event }: FlexibleEventItemProps) {
  const { mutate: markAsFlexible } = useMarkAsFlexible();
  const { mutate: unmarkAsFlexible } = useUnmarkAsFlexible();

  const [isFlexible, setIsFlexible] = useState(event.isMarkedAsFlexible);

  const handleToggle = (checked: boolean) => {
    setIsFlexible(checked);
    if (checked) {
      markAsFlexible({ recurringRemoteEventId: event.recurringRemoteEventId });
    } else {
      unmarkAsFlexible({ recurringRemoteEventId: event.recurringRemoteEventId });
    }
  };

  return (
    <Paper p="md" withBorder>
      <Group justify="space-between">
        <Group>
          <IconCalendar size={20} />
          <div>
            <Text>{event.summary}</Text>
            <Text size="sm" c="dimmed">
              {getRecurrenceText(event)}
            </Text>
          </div>
        </Group>
        <Switch
          checked={isFlexible}
          onChange={(e) => handleToggle(e.currentTarget.checked)}
          label={event.flexibilityType === 'movable' ? 'Mark as movable' : 'Mark as skippable'}
        />
      </Group>
    </Paper>
  );
}
