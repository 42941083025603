import { Navigate, RouteObject } from 'react-router-dom';

import { UPDATE_NAV_LINKS, UpdatePageContainer } from '../components/CodewordTemplateUpdate';

export const updateRoutes: RouteObject[] = [
  {
    path: '/:id',
    element: <UpdatePageContainer />,
    children: [
      ...UPDATE_NAV_LINKS.map((navLink) => ({
        path: navLink.relativePath,
        element: navLink.element,
      })),
      {
        path: '',
        element: <Navigate to={UPDATE_NAV_LINKS[0].relativePath} />,
      },
    ],
  },
];
