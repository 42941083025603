import { useMutation, useQueryClient } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { BASE_API_PATH } from '../constants';
import { FlexibleEvent } from '../types';

interface UnmarkAsFlexibleParams {
  recurringRemoteEventId: string;
}

const unmarkAsFlexible = async ({ recurringRemoteEventId }: UnmarkAsFlexibleParams): Promise<FlexibleEvent> => {
  const { data } = await axios.delete<FlexibleEvent>(`${BASE_API_PATH}/${recurringRemoteEventId}/unmark`);
  return data;
};

export const useUnmarkAsFlexible = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: unmarkAsFlexible,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['flexible-events'] });
    },
  });
};
