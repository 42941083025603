import { transformTimeWindowResponse } from '@utils/transformTimeWindowResponse';

import { LocationOverride, LocationOverrideResponse } from '../types';

import { transformLocationResponse } from './transformLocationResponse';
import { transformPointOfInterestResponse } from './transformPointOfInterestResponse';

export const transformLocationOverrideResponse = (locationOverride: LocationOverrideResponse): LocationOverride => {
  return {
    id: locationOverride.id,
    userId: locationOverride.userId,
    location: locationOverride.location ? transformLocationResponse(locationOverride.location) : null,
    timeWindow: transformTimeWindowResponse(locationOverride.timeWindow),
    pointOfInterest: locationOverride.pointOfInterest
      ? transformPointOfInterestResponse(locationOverride.pointOfInterest)
      : null,
  };
};
