import { FC, ReactNode } from 'react';

import { NavLink as MantineNavLink } from '@mantine/core';
import { Link } from 'react-router-dom';

import classes from './NavLink.module.css';

interface NavLinkProps {
  to: string;
  label: string;
  leftSection?: ReactNode;
  active?: boolean;
  onClick?: () => void;
}

export const NavLink: FC<NavLinkProps> = (props) => {
  return <MantineNavLink {...props} component={Link} className={classes.transition} />;
};
