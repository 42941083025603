import { useQuery } from '@tanstack/react-query';

import { axios } from '@lib/axios';

import { BASE_API_PATH } from '../constants';
import { FlexibleEvent } from '../types';

const getFlexibleEvents = async (): Promise<FlexibleEvent[]> => {
  const { data } = await axios.get<{ events: FlexibleEvent[] }>(BASE_API_PATH);
  return data.events;
};

export const useFlexibleEvents = () => {
  return useQuery({
    queryKey: ['flexible-events'],
    queryFn: getFlexibleEvents,
  });
};
