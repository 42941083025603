import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { PUBLIC_ROUTES } from '@routes/enums';

import { useFirebaseAuthState } from './useFirebaseAuthState';

const GOOGLE_WEB_CLIENT_ID = import.meta.env.VITE_GOOGLE_WEB_CLIENT_ID || '';

const OAUTH_WINDOW_WIDTH = 600;
const OAUTH_WINDOW_HEIGHT = 800;

const EXTERNAL_SCOPE = 'openid profile email https://www.googleapis.com/auth/calendar';
const BLOCK_BOT_SCOPE = 'openid profile email https://www.googleapis.com/auth/gmail.modify';

const BLOCKBOT_ID = 'blockbot';

export const useGoogleOAuthPopup = (isForBlockBot?: boolean, isForReadonly?: boolean) => {
  const authState = useFirebaseAuthState();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [accessToken, setAccessToken] = useState<string>('');
  const [idToken, setIdToken] = useState<string>('');

  const scope = isForBlockBot ? BLOCK_BOT_SCOPE : EXTERNAL_SCOPE;
  const state = isForBlockBot ? BLOCKBOT_ID : authState.uid;

  const openPopup = () => {
    const params = {
      client_id: GOOGLE_WEB_CLIENT_ID,
      scope,
      response_type: 'code',
      redirect_uri: isForReadonly
        ? `${window.location.origin}${PUBLIC_ROUTES.GOOGLE_OAUTH_READONLY}`
        : `${window.location.origin}${PUBLIC_ROUTES.GOOGLE_OAUTH}`,
      prompt: 'consent select_account',
      state: state ?? '',
      access_type: isForReadonly ? 'online' : 'offline',
    };
    const newOAuthUrlCode = new URLSearchParams(params).toString();

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const left = (screenWidth - OAUTH_WINDOW_WIDTH) / 2;
    const top = (screenHeight - OAUTH_WINDOW_HEIGHT) / 2;
    setIsPopupOpen(true);

    return window.open(
      `https://accounts.google.com/o/oauth2/v2/auth?${newOAuthUrlCode}`,
      '',
      `popup=true,width=600,height=800,left=${left},top=${top}`,
    );
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // We will send an account-added message from the popup window
      // on success which will let us know when to refetch a user's calendars
      if (event.data.type === 'account-added') {
        queryClient.invalidateQueries({ queryKey: ['calendarsByAccounts', authState.uid] });
        setIsPopupOpen(false);
      } else if (event.data.type === 'oauth-success') {
        setAccessToken(event.data.accessToken);
        setIdToken(event.data.idToken);
        setIsPopupOpen(false);
      } else if (event.data.type === 'account-added-error' || event.data.type === 'oauth-error') {
        setError(event.data.message);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [authState.uid, queryClient]);

  return { openPopup, error, isPopupOpen, accessToken, idToken };
};
