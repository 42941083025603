import { FC } from 'react';

import { Link } from 'react-router-dom';

import { PRIVATE_ROUTES } from '@routes/enums';

import { BlockitLogo } from './BlockitLogo';
import { TooltipActionIcon } from './TooltipActionIcon';

interface HomeLinkLogoProps {
  size?: number | string;
}

export const HomeLinkLogo: FC<HomeLinkLogoProps> = ({ size }) => {
  return (
    <TooltipActionIcon
      component={Link}
      to={PRIVATE_ROUTES.INTEGRATIONS}
      variant="subtle"
      size={size}
      icon={<BlockitLogo />}
      display="block"
    />
  );
};
