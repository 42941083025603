import { DateTime } from 'luxon';

import { TimeOfDay } from '@/types';

export const convertTimeOfDayToDateTime = (timeOfDay: TimeOfDay): DateTime => {
  const [hours, minutes] = timeOfDay.split(':').map(Number);
  return DateTime.fromObject({ hour: hours, minute: minutes });
};

export const convertDateTimeToTimeOfDay = (dateTime: DateTime): TimeOfDay => {
  return dateTime.toFormat('HH:mm') as TimeOfDay;
};

export const getDiffInMinutesForTimeOfDays = (time1: TimeOfDay, time2: TimeOfDay): number => {
  const dt1 = convertTimeOfDayToDateTime(time1);
  const dt2 = convertTimeOfDayToDateTime(time2);
  return dt1.diff(dt2, 'minutes').minutes;
};

export const addToTimeOfDay = (time: TimeOfDay, hoursToAdd: number): TimeOfDay => {
  const dt = convertTimeOfDayToDateTime(time);
  const newDt = dt.plus({ hours: hoursToAdd });
  const endOfDay = DateTime.fromObject({ hour: 23, minute: 59 });

  return convertDateTimeToTimeOfDay(newDt <= endOfDay ? newDt : endOfDay);
};
