import { FC, useState } from 'react';

import { Button, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';
import type { ConfirmationResult } from 'firebase/auth';

import { formatFirebaseError } from '../utils/formatFirebaseError';
import { signIn } from '../utils/signIn';

interface CodeInputFormProps {
  confirmationRef: React.MutableRefObject<ConfirmationResult | undefined>;
}

export const CodeInputForm: FC<CodeInputFormProps> = (props) => {
  const { confirmationRef } = props;

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    initialValues: {
      code: '',
    },
    validate: {
      code: (code) => (code ? null : 'Code is required'),
    },
  });

  const onSubmit = async (values: { code: string }) => {
    if (!confirmationRef.current) {
      // TODO: Throw error in this case
      return;
    }
    setIsLoading(true);
    const code = values.code;
    try {
      const userCredentials = await confirmationRef.current.confirm(code);
      await signIn(userCredentials.user);
    } catch (error) {
      if (error instanceof FirebaseError) {
        error.code;
        form.setErrors({ code: formatFirebaseError(error.code) });
      } else if (error instanceof AxiosError && error.response?.data?.message) {
        form.setErrors({ code: error.response.data.message });
      } else {
        form.setErrors({ code: 'Error confirming code' });
      }
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <TextInput {...form.getInputProps('code')} autoComplete="one-time-code" placeholder="Enter code" />
        <Button loading={isLoading} type="submit" radius="lg">
          Enter code
        </Button>
      </Stack>
    </form>
  );
};
