import { useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { PUBLIC_ROUTES } from '@routes/enums';
import { generateCodeChallenge, generateCodeVerifier } from '@utils/stringUtil';

import { useFirebaseAuthState } from './useFirebaseAuthState';

const MICROSOFT_CLIENT_ID = import.meta.env.VITE_MICROSOFT_CLIENT_ID || '';

const OAUTH_WINDOW_WIDTH = 600;
const OAUTH_WINDOW_HEIGHT = 800;

const EXTERNAL_SCOPE = 'openid profile email offline_access Calendars.ReadWrite.Shared';

export const useMicrosoftOAuthPopup = () => {
  const authState = useFirebaseAuthState();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = async () => {
    const verifier = generateCodeVerifier();
    const challenge = await generateCodeChallenge(verifier);

    const params = {
      client_id: MICROSOFT_CLIENT_ID,
      scope: EXTERNAL_SCOPE,
      response_type: 'code',
      response_mode: 'query',
      redirect_uri: `${window.location.origin}${PUBLIC_ROUTES.MICROSOFT_OAUTH}`,
      state: `${authState.uid}#${verifier}`,
      code_challenge_method: 'S256',
      code_challenge: challenge,
    };
    const newOAuthUrlCode = new URLSearchParams(params).toString();

    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const left = (screenWidth - OAUTH_WINDOW_WIDTH) / 2;
    const top = (screenHeight - OAUTH_WINDOW_HEIGHT) / 2;
    setIsPopupOpen(true);

    return window.open(
      `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${newOAuthUrlCode}`,
      '',
      `popup=true,width=600,height=800,left=${left},top=${top}`,
    );
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // We will send an account-added message from the popup window
      // on success which will let us know when to refetch a user's calendars
      if (event.data.type === 'microsoft-account-added') {
        queryClient.invalidateQueries({ queryKey: ['calendarsByAccounts', authState.uid] });
        setIsPopupOpen(false);
      } else if (event.data.type === 'microsoft-account-added-error') {
        setError(event.data.message);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [authState.uid, queryClient]);

  return { openPopup, error, isPopupOpen };
};
