import { DayOfWeek } from '@/types';

export interface FlexibleEvent {
  summary: string;
  recurringRemoteEventId: string;
  recurrance: RecurrenceRule;
  flexibilityType: FlexibilityType;
  isMarkedAsFlexible: boolean;
}

export enum FlexibilityType {
  SKIPPABLE = 'skippable',
  MOVABLE = 'movable',
}

export enum Frequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface RecurrenceRule {
  frequency: Frequency;
  interval: number;
  until?: string;
  count?: number;
  byDay?: DayOfWeek[];
  byMonthDay?: number[];
  byMonth?: number[];
}
