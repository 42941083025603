import type { User } from 'firebase/auth';

import { firebaseAuth } from '../config/firebaseAuth';

/**
 * Warning: getCurrentUser will not gracefully handle changes in auth state.
 * Only use this util if you need to access the currentUser in a place that you
 * cannot use hooks.
 *
 * Default to using useFirebaseAuthState so that your components that are dependent
 * on auth state will rerender when the auth state changes.
 */
export const getCurrentUser = (): User | null => {
  return firebaseAuth.currentUser;
};
