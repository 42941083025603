import { FC } from 'react';

import { Input, InputWrapper, InputWrapperProps } from '@mantine/core';
import { E164Number } from 'libphonenumber-js';
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

interface PhoneNumberInputProps extends Omit<InputWrapperProps, 'onChange'> {
  onChange: (value: E164Number | undefined) => void;
  value?: E164Number | undefined;
}

export const PhoneNumberInput: FC<PhoneNumberInputProps> = (props) => {
  const { value, onChange, ...inputWrapperProps } = props;
  return (
    <InputWrapper {...inputWrapperProps}>
      <PhoneInput
        defaultCountry="US"
        initialValueFormat="national"
        placeholder="Enter phone number"
        inputComponent={Input}
        onChange={onChange}
        value={value}
      />
    </InputWrapper>
  );
};
