import { FC } from 'react';

import { Box, Group, Text } from '@mantine/core';

import { Switch } from '@components/Switch';
import { useUser } from '@features/users';

import { useUpdateCalendar } from '../api/updateCalendar';
import { Calendar } from '../types';

interface CalendarDetailsProps {
  accountId: string;
  calendar: Calendar;
}

export const CalendarDetails: FC<CalendarDetailsProps> = (props) => {
  const { accountId, calendar } = props;
  const { mutate: updateCalendar } = useUpdateCalendar();
  const { data: user } = useUser();

  if (!user) {
    return null;
  }
  return (
    <Group>
      <Box>
        <Switch
          size="xs"
          checked={calendar.isSelected}
          onChange={(event) =>
            updateCalendar({ accountId, userId: user.id, isSelected: event.target.checked, calendar })
          }
        />
      </Box>
      <Box>
        <Text>{calendar.name}</Text>
      </Box>
    </Group>
  );
};
