import React from 'react';

import { Anchor, Group, Stack, Text } from '@mantine/core';
import { IconBrandLinkedin } from '@tabler/icons-react';

import { Attendee } from '../types';

interface AttendeeDisplayProps {
  attendee: Attendee;
}

export const AttendeeDisplay: React.FC<AttendeeDisplayProps> = ({ attendee }) => {
  const displayName = attendee.name || attendee.email;

  if (attendee.linkedInUrl) {
    return (
      <Stack gap="1">
        <Text size="sm">{displayName}</Text>
        <Anchor href={attendee.linkedInUrl} target="_blank" rel="noopener noreferrer" size="xs">
          <Group gap="1">
            <IconBrandLinkedin size="12" />
            <Text>LinkedIn</Text>
          </Group>
        </Anchor>
      </Stack>
    );
  }

  return (
    <Text size="sm" span>
      {displayName}
    </Text>
  );
};
