import { FC } from 'react';

import { Group, Title } from '@mantine/core';
import { IconProps } from '@tabler/icons-react';

import { InfoTooltip } from '@components/InfoTooltip';

interface SettingsSectionTitleProps {
  title: string;
  description: string;
  Icon: React.ComponentType<IconProps>;
}

export const SettingsSectionTitle: FC<SettingsSectionTitleProps> = (props) => {
  const { title, description, Icon } = props;
  return (
    <Group gap="xs">
      <Icon />
      <Title order={3}>{title}</Title>
      <InfoTooltip description={description} />
    </Group>
  );
};
