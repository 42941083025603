import { useRef, useState } from 'react';

import type { ConfirmationResult } from 'firebase/auth';

import { CodeInputForm } from './CodeInputForm';
import { PhoneInputForm } from './PhoneInputForm';

export const FirebaseLogin = () => {
  const confirmationRef = useRef<undefined | ConfirmationResult>();
  const [showCodeInput, setShowCodeInput] = useState(false);

  const onPhoneInputSuccess = () => {
    setShowCodeInput(true);
  };
  return (
    <>
      {!showCodeInput && <PhoneInputForm confirmationRef={confirmationRef} onPhoneInputSuccess={onPhoneInputSuccess} />}
      {showCodeInput && <CodeInputForm confirmationRef={confirmationRef} />}
    </>
  );
};
