import { FC } from 'react';

import { Box, Stack, Text, Title } from '@mantine/core';

import { SideContent } from '@components/SideContent';

const SLACK_APP_CLIENT_ID = import.meta.env.VITE_SLACK_APP_CLIENT_ID || '5419298431573.6291637482614';
const BOT_SCOPE = 'app_mentions:read,chat:write,commands,users:read,users:read.email,assistant:write,im:history';

export const BetaPage: FC = () => {
  return (
    <SideContent
      sideContentSize="300"
      sideContent={
        <Stack>
          <Box>
            <Title order={4}>Beta</Title>
            <Text c="dimmed" size="sm">
              Beta features.
            </Text>
          </Box>
        </Stack>
      }
    >
      <Stack>
        <Title>Connect Slack</Title>
        <a
          // eslint-disable-next-line max-len
          href={`https://slack.com/oauth/v2/authorize?client_id=${SLACK_APP_CLIENT_ID}&scope=${BOT_SCOPE}&user_scope=`}
        >
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            // eslint-disable-next-line max-len
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
      </Stack>
    </SideContent>
  );
};
//
