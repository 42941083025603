import { ComponentPropsWithoutRef } from 'react';

/* eslint-disable max-len */
interface BlockitLogoProps extends ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export const BlockitLogo = ({ size, style }: BlockitLogoProps) => {
  return (
    <svg
      width="500"
      height="500"
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size, ...style }}
    >
      <path
        d="M216.081 27.2413L110.789 91.5241C107.815 93.3402 106 96.574 106 100.059L106 371.394C106 378.908 113.976 383.738 120.634 380.255L227.095 324.576C230.407 322.845 232.477 319.411 232.461 315.673L231.292 35.7346C231.259 27.9459 222.729 23.1827 216.081 27.2413Z"
        fill="#1B5277"
      />
      <path
        d="M241.576 349.122L124.363 410.772C116.951 414.67 117.328 425.408 124.995 428.777L242.191 480.279C244.693 481.378 247.535 481.406 250.058 480.356L373.784 428.846C381.695 425.553 382.07 414.486 374.401 410.664L250.691 349.022C247.813 347.588 244.422 347.625 241.576 349.122Z"
        fill="#1B5277"
      />
      <path
        d="M265.374 324.538L379.58 380.805C386.226 384.08 394 379.243 394 371.835V250.753C394 247.126 392.036 243.784 388.869 242.018L275.966 179.088C269.34 175.394 261.175 180.135 261.098 187.72L259.794 315.465C259.755 319.311 261.924 322.838 265.374 324.538Z"
        fill="#1B5277"
      />
    </svg>
  );
};
