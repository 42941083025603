import { formatDuration } from '@/utils/dateTime';
import { ConsecutiveMeetingMinutesBuffer, PreferenceSettings } from '@features/preferences';

export const KEYS_TO_COMPARE: (keyof PreferenceSettings)[] = [
  'inPersonDuration',
  'preferredStartingTimes',
  'linkType',
  'link',
  'linkName',
  'defaultAccountId',
  'availability',
  'timeZone',
  'travelTimeBufferInMinutes',
  'flightTravelTimeBufferInMinutes',
  'consecutiveMeetingBuffer',
  'everyMeetingBufferInMinutes',
  'maxMeetingMinutesPerDay',
  'numDaysToNudge',
  'maxTimesToNudge',
];

export const KEYS_TO_DISPLAY: (keyof PreferenceSettings)[] = [
  'duration',
  'inPersonDuration',
  'preferredStartingTimes',
  'linkType',
  'link',
  'linkName',
  'defaultAccountId',
  'timeZone',
  'travelTimeBufferInMinutes',
  'flightTravelTimeBufferInMinutes',
  'consecutiveMeetingBuffer',
  'everyMeetingBufferInMinutes',
  'maxMeetingMinutesPerDay',
  'numDaysToNudge'
];


export const comparePreferences = (newPrefs: PreferenceSettings, userPrefs: PreferenceSettings) => {
  const differentPreferences: Partial<Record<keyof PreferenceSettings, PreferenceSettings[keyof PreferenceSettings]>> =
    {};

  KEYS_TO_COMPARE.forEach((key) => {
    const value: PreferenceSettings[typeof key] = newPrefs[key];
    if (value === null) {
      return;
    }

    if (key === 'availability' && !newPrefs.availability) {
      return;
    }
    if (key === 'numDaysToNudge') {
      if (userPrefs.nudgeEnabled !== newPrefs.nudgeEnabled) {
        differentPreferences[key] = value;
      } else if (newPrefs.nudgeEnabled && newPrefs[key] !== userPrefs[key]) {
        differentPreferences[key] = value;
      } else if (!newPrefs.nudgeEnabled && newPrefs['maxTimesToNudge'] !== userPrefs['maxTimesToNudge']) {
        differentPreferences[key] = value;
      }
      return;
    }

    if (JSON.stringify(newPrefs[key]) !== JSON.stringify(userPrefs[key])) {
      const value: PreferenceSettings[typeof key] = newPrefs[key];
      if (value === null) {
        return;
      }
      differentPreferences[key] = value;
    }
  });

  return differentPreferences;
};

export const formatKeyAndValue = (
  key: string,
  value: PreferenceSettings[keyof PreferenceSettings],
  newPrefs: PreferenceSettings,
): string => {
  switch (key) {
    case 'duration':
      return `${formatDuration(value as number, true)} meetings`;
    case 'inPersonDuration':
      return `${formatDuration(value as number, true)} in-person meetings`;
    case 'travelTimeBufferInMinutes':
      return `${formatDuration(value as number, true)} travel buffer`;
    case 'flightTravelTimeBufferInMinutes':
      return `${formatDuration(value as number, true)} flight travel buffer`;
    case 'everyMeetingBufferInMinutes':
      return `${formatDuration(value as number, true)} buffer between meetings`;
    case 'maxMeetingMinutesPerDay':
      return `Max ${formatDuration(value as number, true)} of meetings per day`;
    case 'consecutiveMeetingBuffer':
      return formatConsecutiveMeetingBuffer(value as ConsecutiveMeetingMinutesBuffer);
    case 'numDaysToNudge':
      return newPrefs.nudgeEnabled
        ? `Nudge every ${value} days up to ${newPrefs.maxTimesToNudge} times`
        : 'Nudge disabled';
    case 'timeZone':
      return `Time zone: ${value}`;
    case 'availability':
      return 'Preferred availability blocks';
    default:
      return typeof value === 'string' ? value : JSON.stringify(value);
  }
};

const formatConsecutiveMeetingBuffer = (value: ConsecutiveMeetingMinutesBuffer) => {
  if (typeof value === 'object' && value !== null) {
    const { durationInMinutes, maxMeetingMinutes } = value;
    return `${formatDuration(durationInMinutes)} after every ${formatDuration(maxMeetingMinutes)} of meetings`;
  }
  return JSON.stringify(value);
};
