import React from 'react';

import { Box } from '@mantine/core';
import { DateTime } from 'luxon';

import { GroupedMeetings } from './GroupedMeetings';

export const PastMeetingsPage: React.FC = () => {
  return (
    <Box>
      <GroupedMeetings start={DateTime.now().minus({ years: 5 })} end={DateTime.now()} order="DESC" />
    </Box>
  );
};
