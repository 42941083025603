import { FC, ReactNode } from 'react';

import { Box } from '@mantine/core';

import classes from './GradientBackground.module.css';

interface GradientBackgroundProps {
  children: ReactNode;
}

export const GradientBackground: FC<GradientBackgroundProps> = ({ children }) => {
  return <Box className={classes.gradient}>{children}</Box>;
};
