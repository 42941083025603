import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import { axios } from '@lib/axios';

import { Attendee, MeetingForDisplay, MeetingsParams } from '../types';

interface MeetingsResponse {
  meetings: {
    id: string;
    startTime: string;
    endTime: string;
    threadSummary: string | null;
    codewordTemplateName: string | null;
    title: string | null;
    attendees: Attendee[];
    location: string | null;
  }[];
  totalCount: number;
}

interface MeetingsData {
  meetings: MeetingForDisplay[];
  totalCount: number;
}

const fetchMeetings = async (params: MeetingsParams): Promise<MeetingsData> => {
  const { data } = await axios.get<MeetingsResponse>('/digests/meetings', { params });
  return {
    meetings: data.meetings.map((meeting) => ({
      ...meeting,
      startTime: DateTime.fromISO(meeting.startTime),
      endTime: DateTime.fromISO(meeting.endTime),
    })),
    totalCount: data.totalCount,
  };
};

export const useMeetings = (params: MeetingsParams) => {
  return useQuery<MeetingsData, Error>({
    queryKey: ['meetings', { ...params }],
    queryFn: () => fetchMeetings(params),
    retry: false,
  });
};
