import { useMutation } from '@tanstack/react-query';

import { axios } from '@lib/axios';

interface ConnectSlackAccountRequest {
  userId: string;
  code: string;
}

const connectSlackAccount = async (request: ConnectSlackAccountRequest) => {
  return await axios.post(`/slack/connect`, request);
};

export const useSlackConnectAccount = () => {
  return useMutation({
    mutationFn: connectSlackAccount,
  });
};
