export enum SUB_SECTIONS {
  WORKFLOWS = 'workflows',
  SETTINGS = 'settings',
}

export enum PRIVATE_ROUTES {
  FEED = '/feed',
  INTEGRATIONS = '/integrations',
  PLACES = '/places',
  AVAILABILITY = '/availability',
  PREFERENCES = '/preferences',
  BETA = '/beta',
  CODEWORDS = '/codewords',
  FLEXIBILITY = '/flexibility',
  CONTACTS = '/contacts',
}

export enum INTERNAL_ROUTES {
  INTERNAL = '/internal',
}

export enum PUBLIC_ROUTES {
  GOOGLE_OAUTH = '/googleoauth',
  GOOGLE_OAUTH_READONLY = '/googleoauth-readonly',
  SLACK_OAUTH = '/slackoauth',
  LOGIN = '/login',
  MICROSOFT_OAUTH = '/microsoftoauth',
  ZOOM_OAUTH = '/zoom/callback',
  WAITLIST = '/waitlist',
}
