import { Group, Text, UnstyledButton } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';

import { SortDirection, SortField } from '../types';

interface SortableHeaderProps {
  field: SortField;
  activeField: SortField | null;
  direction: SortDirection;
  onSort: (field: SortField) => void;
  title: string;
}

export function SortableHeader({ field, activeField, direction, onSort, title }: SortableHeaderProps) {
  return (
    <UnstyledButton onClick={() => onSort(field)}>
      <Group gap={4} wrap="nowrap">
        <Text size="sm" fw={600}>
          {title}
        </Text>
        {field === activeField ? (
          direction === 'asc' ? (
            <IconChevronUp size={14} />
          ) : direction === 'desc' ? (
            <IconChevronDown size={14} />
          ) : (
            <IconSelector size={14} />
          )
        ) : (
          <IconSelector size={14} />
        )}
      </Group>
    </UnstyledButton>
  );
}
