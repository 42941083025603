import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AnalyticsEventName } from '@/features/analytics/types';
import { track } from '@features/analytics';
import { axios, RequestError } from '@lib/axios';

import { API_BASE_URL, QUERY_KEY } from './constants';

const deleteCodewordTemplate = async (id: string): Promise<void> => {
  await axios.delete(`${API_BASE_URL}/${id}`);
};

export const useDeleteCodewordTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation<void, RequestError, string>({
    mutationFn: deleteCodewordTemplate,
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

      // Track the delete event
      track({
        type: AnalyticsEventName.CodewordTemplateDeleted,
        data: { templateId: id },
      });
    },
  });
};
