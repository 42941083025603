import { useEffect, useState } from 'react';

import { Radio, Stack } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { QUERY_KEY } from '@features/codeword-templates/api/constants';
import { useCodewordTemplate } from '@features/codeword-templates/api/getCodewordTemplate';
import { Availability, AvailabilityCalendar, getEmptyAvailability, useUpdatePreferences } from '@features/preferences';

export const PreferredBlocksPage = () => {
  const [selectedValue, setSelectedValue] = useState<string>('default');
  const [availabilityDraft, setAvailabilityDraft] = useState<Availability | null>(null);

  const { id } = useParams();
  const { data: codewordTemplate } = useCodewordTemplate(id);
  const queryClient = useQueryClient();
  const { mutate: updatePreferences, isPending: isUpdating } = useUpdatePreferences({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY, id] });
    },
  });

  useEffect(() => {
    // Setting availability draft so that we don't lose the user's changes when we set selected value to default
    if (!availabilityDraft && codewordTemplate) {
      if (codewordTemplate.preferenceSettings.availability) {
        setSelectedValue('custom');
        setAvailabilityDraft(codewordTemplate.preferenceSettings.availability);
      } else {
        setSelectedValue('default');
        setAvailabilityDraft(getEmptyAvailability());
      }
    }
  }, [codewordTemplate?.preferenceSettings.availability, availabilityDraft, codewordTemplate]);

  if (!codewordTemplate || !availabilityDraft) {
    return null;
  }

  const updateAvailability = (availability: Availability | null) => {
    if (availability) {
      setAvailabilityDraft(availability);
    }
    updatePreferences({ ...codewordTemplate.preferenceSettings, availability });
  };

  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
    if (value === 'default') {
      updateAvailability(null);
    } else {
      updateAvailability(availabilityDraft);
    }
  };

  return (
    <>
      <Radio.Group
        label="Preferred Blocks"
        description="Choose default or add custom preferred blocks"
        defaultValue="default"
        value={selectedValue}
        onChange={handleRadioChange}
        mb="md"
      >
        <Stack gap="xs" mt="xs">
          <Radio value="default" label="Use default" />
          <Radio value="custom" label="Add custom" />
        </Stack>
      </Radio.Group>
      {selectedValue === 'custom' && (
        <AvailabilityCalendar
          initialAvailability={availabilityDraft}
          updateAvailability={updateAvailability}
          isUpdating={isUpdating}
        />
      )}
    </>
  );
};
