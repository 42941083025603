import { FC } from 'react';

import { Box } from '@mantine/core';
import { Map } from '@vis.gl/react-google-maps';

import { SAN_FRANCISCO_MAP_CENTER } from '@constants/index';
import { PointOfInterest } from '@features/location/types';

import { PointOfInterestMarkers } from './PointOfInterestMarkers';

const MAP_ID = 'location-map';

interface PointOfInterestMapProps {
  existingPointsOfInterest: PointOfInterest[];
}

export const PointOfInterestMap: FC<PointOfInterestMapProps> = ({ existingPointsOfInterest }) => {
  return (
    <Box h={300}>
      <Map
        mapId={MAP_ID}
        gestureHandling={'greedy'}
        disableDefaultUI
        defaultZoom={10}
        defaultCenter={SAN_FRANCISCO_MAP_CENTER}
      >
        <PointOfInterestMarkers pointsOfInterest={existingPointsOfInterest} />
      </Map>
    </Box>
  );
};
