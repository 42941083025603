import React, { useEffect, useState } from 'react';

import { Box, Transition } from '@mantine/core';

import styles from './TypewriterText.module.css';

interface TypewriterTextProps {
  text: string;
  delay?: number;
  onComplete?: () => void;
}

const TypewriterText: React.FC<TypewriterTextProps> = ({ text, delay = 25, onComplete }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
      setShowCursor(false);
    }
  }, [currentIndex, delay, text, onComplete]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <Box className={styles.container} component="div">
      <span>{displayText}</span>
      <Transition
        mounted={showCursor && currentIndex < text.length}
        transition="fade"
        duration={300}
        timingFunction="ease"
      >
        {(styles) => <span className={styles.cursor}>|</span>}
      </Transition>
    </Box>
  );
};

export default TypewriterText;
