import React, { useEffect, useState } from 'react';

import { Group } from '@mantine/core';

import TypewriterText from '@components/TypewriterText';

import styles from './TypewriterLine.module.css';

interface TypewriterLineProps {
  text: string;
  onComplete?: () => void;
  delay?: number;
}

export const TypewriterLine: React.FC<TypewriterLineProps> = ({ text, onComplete, delay = 0 }) => {
  const [shouldRender, setShouldRender] = useState(delay === 0);

  useEffect(() => {
    if (delay > 0) {
      const timeout = setTimeout(() => {
        setShouldRender(true);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [delay]);

  return (
    <Group wrap="nowrap" gap="xs" className={styles.container}>
      <div className={styles.text}>
        {shouldRender ? (
          <TypewriterText text={text} onComplete={onComplete} />
        ) : (
          <span style={{ opacity: 0 }}>placeholder</span>
        )}
      </div>
    </Group>
  );
};
